<div class="container-fluid top-pad" >

  <div class="row top-pad">

    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <div>
            <h5>Project Detail</h5>
          </div>

          <div class="card-subtitle mb-2 text-muted">
            <span>Manage a Project record for a client</span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row sub-pad" *ngIf="this.flashMessage">
    <div class="col-lg-6 col-sm-12">
      <div class="alert alert-danger" role="alert" *ngIf="this.flashMessage.isError">
        <h4 class="alert-heading">{{ this.flashMessage.messageHead }}</h4>
        <p>{{ this.flashMessage.messageSub }}</p>
        <hr>
        <div class="text-right">
          <a class="btn btn-outline-info my-2 my-sm-0" data-dismiss="alert">Dismiss</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row sub-pad">
    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <form [formGroup]="entityFormGroup" autocomplete="off">
            <input type="hidden" value="" id="id" name="id" />

            <!-- Only show if client-id is not present -->
            <div *ngIf="this.visibleClientSelect">
              <div class="form-group">
                <label for="name">Please select the Client that will be associated with this new Project</label>
                <select
                  required
                  class="form-control"
                  id="clientId"
                  name="clientId"
                  formControlName="clientId"
                >
                  <option *ngFor="let element of this.subjectClientList.getValue()" [ngValue]="element.id">
                    {{ element.name }}
                  </option>
                </select>

              </div>

              <validator-msg [component]="this" formElementName="clientId"></validator-msg>
            </div>

            <div class="form-group">
              <label for="name">Name</label>
              <input type="text"
                     class="form-control"
                     id="name"
                     name="name"
                     placeholder="Name"
                     formControlName="name"
                     maxlength="250"
                     required
                     [ngClass]="{'invalid': !this.isValid('name')}">
            </div>

            <validator-msg [component]="this" formElementName="name"></validator-msg>

            <div class="form-group">
              <label for="coordLat">Latitude</label>
              <input type="text"
                     class="form-control"
                     id="coordLat"
                     name="coordLat"
                     placeholder="Latitude"
                     formControlName="coordLat"
                     [ngClass]="{'invalid': !this.isValid('coordLat')}">
            </div>

            <validator-msg [component]="this" formElementName="coordLat"></validator-msg>

            <div class="form-group">
              <label for="coordLon">Longitude</label>
              <input type="text"
                     class="form-control"
                     id="coordLon"
                     name="coordLon"
                     placeholder="Longitude"
                     formControlName="coordLon"
                     [ngClass]="{'invalid': !this.isValid('coordLon')}">
            </div>

            <validator-msg [component]="this" formElementName="coordLon"></validator-msg>

            <div class="form-group">
              <label for="address1">Address Line 1</label>
              <input type="text"
                     class="form-control"
                     id="address1"
                     name="address1"
                     placeholder=""
                     formControlName="address1"
                     autocomplete="off"
                     [ngClass]="{'invalid': !this.isValid('address1')}">
            </div>

            <validator-msg [component]="this" formElementName="address1"></validator-msg>

            <div class="form-group">
              <label for="address2">Address Line 2</label>
              <input type="text"
                     class="form-control"
                     id="address2"
                     name="address2"
                     placeholder=""
                     formControlName="address2"
                     autocomplete="off"
                     [ngClass]="{'invalid': !this.isValid('address2')}">
            </div>

            <validator-msg [component]="this" formElementName="address2"></validator-msg>

            <div class="form-group">
              <label for="city">City</label>
              <input type="text"
                     class="form-control"
                     id="city"
                     name="city"
                     placeholder="City"
                     formControlName="city"
                     autocomplete="off"
                     [ngClass]="{'invalid': !this.isValid('city')}">
            </div>

            <validator-msg [component]="this" formElementName="city"></validator-msg>

            <div class="form-group">
              <label for="state">State</label>
              <input type="text"
                     class="form-control"
                     id="state"
                     name="state"
                     placeholder="State"
                     formControlName="state"
                     autocomplete="off"
                     [ngClass]="{'invalid': !this.isValid('state')}">
            </div>

            <validator-msg [component]="this" formElementName="state"></validator-msg>

            <div class="form-group">
              <label for="zip">Zip</label>
              <input type="text"
                     class="form-control"
                     id="zip"
                     name="zip"
                     placeholder="Zip"
                     formControlName="zip"
                     autocomplete="off"
                     [ngClass]="{'invalid': !this.isValid('zip')}">
            </div>

            <validator-msg [component]="this" formElementName="zip"></validator-msg>

            <div class="form-group">
              <label for="receivingWaterOutfall">Receiving Water Outfall</label>
              <input type="text"
                     class="form-control"
                     id="receivingWaterOutfall"
                     name="receivingWaterOutfall"
                     placeholder=""
                     formControlName="receivingWaterOutfall"
                     autocomplete="off"
                     [ngClass]="{'invalid': !this.isValid('receivingWaterOutfall')}">
            </div>

            <validator-msg [component]="this" formElementName="receivingWaterOutfall"></validator-msg>

            <div class="form-group">
              <label for="receivingWaterOutfall">Acres Disturbed</label>
              <input type="number"
                     class="form-control"
                     id="acresDisturbed"
                     name="acresDisturbed"
                     placeholder=""
                     formControlName="acresDisturbed"
                     autocomplete="off"
                     maxlength="5"
                     min="0.001"
                     max="99999"
                     [ngClass]="{'invalid': !this.isValid('acresDisturbed')}">
            </div>

            <div class="invalid-form-field" [ngClass]="{'invisible': this.isValid('acresDisturbed')}">
              <span>{{ this.formElements.acresDisturbed.error }}</span>
            </div>

            <app-flash-msg [flashMessage]="this.flashMessage"></app-flash-msg>
            <app-bcs-button-group [backCancelSave]="this"></app-bcs-button-group>

          </form>
        </div>
      </div>
    </div>
  </div>

</div>
