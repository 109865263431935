<div class="container-fluid top-pad" >

  <div class="row top-pad">

    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <div>
            <h5>Client Detail</h5>
          </div>

          <div class="card-subtitle mb-2 text-muted">
            <span>Manage a Client record to track associated projects, permits, and inspections</span>
          </div>
        </div>
      </div>
    </div>
  </div>


<!--  <div class="row sub-pad" *ngIf="this.flashMessage">-->
<!--  <div class="row sub-pad">-->
<!--    <div class="col-lg-6 col-sm-12">-->
<!--&lt;!&ndash;      <div class="alert alert-danger" role="alert" *ngIf="this.flashMessage.isError">&ndash;&gt;-->
<!--      <div class="alert alert-danger" role="alert" >-->
<!--          <h4 class="alert-heading">{{ this.flashMessage.messageHead }}</h4>-->
<!--          <p>{{ this.flashMessage.messageSub }}</p>-->
<!--          <hr>-->
<!--          <div class="text-right">-->
<!--            <a class="btn btn-outline-info my-2 my-sm-0" data-dismiss="alert">Dismiss</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <div class="row sub-pad">
      <div class="col-lg-6 col-sm-12">
        <div class="card rounded-0">
          <div class="card-body">
            <form [formGroup]="entityFormGroup" autocomplete="off">
              <input type="hidden" value="" id="id" name="id" />
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text"
                       class="form-control"
                       id="name"
                       name="name"
                       placeholder="Name"
                       formControlName="name"
                       maxlength="250"
                       required
                       [ngClass]="{'invalid': !this.isValid('name')}"
                >
              </div>

              <validator-msg [component]="this" formElementName="name"></validator-msg>
              <app-flash-msg [flashMessage]="this.flashMessage"></app-flash-msg>
              <app-bcs-button-group [backCancelSave]="this"></app-bcs-button-group>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
