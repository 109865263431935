import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseAddEditComponent} from "../base/base-add-edit.component";
import {ProjectInspection} from "../domain/client-project-inspection";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientProjectService} from "../app-project/client-project-service";
import {FormBuilder} from "@angular/forms";
import {InspectionService} from "./inspection-service";
import {ClientProject} from "../domain/client-project";
import {ClientProjectPermit} from "../domain/client-project-permit";
import {CookieMgr} from "../util/cookie-mgr";

@Component({
  selector: 'app-inspection',
  templateUrl: './app-inspection.component.html',
  styleUrls: ['./app-inspection.component.css']
})
export class AppInspectionComponent extends BaseAddEditComponent<ProjectInspection> {
  formElements = {
    "projectId": this.az09_(2, 250),
    "erosionControlNotes": this.simple(1, 5120),
    "dateOf": this.htmlDate(),
    "currentPhase": this.simple(1, 512),
    "currentWeather": this.simple(1, 512),
    "urlMedia": this.link(),
    "flagged": this.ynbool(),
    "signedBy": this.noop(),
    "question1": this.ynbool(),
    "question2": this.ynbool(),
    "question3": this.ynbool(),
    "question4": this.ynbool(),
    "question5": this.ynbool(),
    "question6": this.ynbool(),
    "question7": this.ynbool(),
    "question8": this.ynbool(),
    "question9": this.ynbool(),
    "question10": this.ynbool(),
    "question11": this.ynbool(),
    "question12": this.ynbool(),
    "question13": this.ynbool(),
    "question14": this.ynbool(),
    "question15": this.ynbool(),
    "question16": this.ynbool(),
    "question17": this.ynbool(),
    "question18": this.ynbool(),
    "question19": this.ynbool(),

  };

  clientProject: ClientProject;

  constructor(location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              entityService: InspectionService,
              fb: FormBuilder,
              protected clientProjectService: ClientProjectService,
              protected changeDetectorRef: ChangeDetectorRef,
              protected cookieMgr: CookieMgr) {

    super(location, router, activatedRoute, entityService, fb);
  }

  ngOnInit() {
    super.ngOnInit();

    this.changeDetectorRef.detectChanges();

    // TODO - [fix] This is an observable "this.subjectEntityId" - so this is faulty
    this.queryParamsSubject.subscribe((nextQueryStringParams) => {
      if(!nextQueryStringParams) {
        return;
      }

      const projectId = nextQueryStringParams["projectId"];

      if(this.projectIdPresentAndEntityIdNotPresent(projectId, this.subjectEntityId)) {
        // console.log("-- aic - 2")
        // console.log(`found inspection id ${projectId} and no subjectEntityId`);

        // TODO - left off here - this needs to be separated from the inspection subject to support new inspections
        this.clientProjectService
          .getById(projectId)
          .subscribe((clientProject) => {
            this.clientProject = clientProject;

            const pi = new ProjectInspection();
            pi.project = clientProject;
            this.targetSubject.next(pi);
          });
      } else if(this.subjectEntityId) {
        // TODO - this is not defined
      }else {
        throw new Error("No project id or subject entity id provided");
      }
    });
  }

  entityName(): string {
    return "inspection";
  }

  getFormElements(): Record<string, any> {
    return this.formElements;
  }


  protected handleParametersExtend(id: string, queryParams: Record<string, string>) {
    this.entityFormGroup.patchValue({"projectId": queryParams["projectId"]});
  }


  updateFormGroup(v: ProjectInspection): any {
    const newEntityFormGroupValue = Object.assign(
      this.fieldsBase(v), {
        projectId: this.fieldDefault(v.project.id),
        dateOf: this.fieldDefault(v.dateOf),
        erosionControlNotes: this.fieldDefault(v.erosionControlNotes),
        currentPhase: this.fieldDefault(v.currentPhase),
        currentWeather: this.fieldDefault(v.currentWeather),
        flagged: this.fieldDefault(`${v.flagged}`),
        urlMedia: this.fieldDefault(v.urlMedia),
        question1: this.fieldDefault(`${v.question1}`),
        question2: this.fieldDefault(`${v.question2}`),
        question3: this.fieldDefault(`${v.question3}`),
        question4: this.fieldDefault(`${v.question4}`),
        question5: this.fieldDefault(`${v.question5}`),
        question6: this.fieldDefault(`${v.question6}`),
        question7: this.fieldDefault(`${v.question7}`),
        question8: this.fieldDefault(`${v.question8}`),
        question9: this.fieldDefault(`${v.question9}`),
        question10: this.fieldDefault(`${v.question10}`),
        question11: this.fieldDefault(`${v.question11}`),
        question12: this.fieldDefault(`${v.question12}`),
        question13: this.fieldDefault(`${v.question13}`),
        question14: this.fieldDefault(`${v.question14}`),
        question15: this.fieldDefault(`${v.question15}`),
        question16: this.fieldDefault(`${v.question16}`),
        question17: this.fieldDefault(`${v.question17}`),
        question18: this.fieldDefault(`${v.question18}`),
        question19: this.fieldDefault(`${v.question19}`),
        signedBy: this.fieldDefault(v.signedBy?.id),
      });

    this.entityFormGroup.setValue(newEntityFormGroupValue);
  }

  /**
   * Is Project ID Present and Entity ID Not Present?
   * This indicates a new entity is being created.
   * @param projectId
   * @param subjectEntityId
   * @protected
   */
  protected projectIdPresentAndEntityIdNotPresent(projectId: string, subjectEntityId: number): boolean {
    return !!projectId && !!!subjectEntityId;
  }
  projectId(): number {
    return this.targetSubject.getValue()?.project.id;
  }

  projectName(): string {
    // console.log(`projectName: ${this.subjectInstance}`);
    // console.log(`.projectName: ${this.targetSubject}`);
    // console.log(`.projectName: ${this.targetSubject.getValue()?.}`);
    // console.dir(this.targetSubject.getValue());

    return this.targetSubject.getValue()?.project.name;
  }

  permit(): ClientProjectPermit {
    // return this.subjectInstance?.clientProject.permits[0];
    // return this.targetSubject.getValue()?.clientProject?.permits[0];
    const permits = this.targetSubject.getValue()?.project?.permits;
    if(permits) {
      return permits[0];
    }else {
      return null;
    }
  }

  // mostRecentPermit(): ClientProjectPermit {
  //   if(!this.clientProject || !this.clientProject.permits) {
  //     return undefined;
  //   }else {
  //     return this.clientProject.permits[this.clientProject.permits.length - 1];
  //   }
  // }

  isSigned(): boolean {
    const entityFormGroupSignedBy = this.entityFormGroup.get("signedBy");
    return !! (entityFormGroupSignedBy && entityFormGroupSignedBy.value);
  }

  signedBy(): string {
    // const entityFormGroupSignedBy = this.entityFormGroup.get("signedBy");
    // return entityFormGroupSignedBy ? entityFormGroupSignedBy.value : undefined;
    return this.entityFormGroup.get("signedBy")?.value;
  }

  isFlagged(): boolean {
    const entityFormGroupFlagged = this.entityFormGroup.get("flagged");
    return entityFormGroupFlagged && entityFormGroupFlagged.value ? `${true}` === entityFormGroupFlagged.value.toLowerCase() : false;
  }

  flagged(): string {
    return this.isFlagged() ? "Remove Flag" : "Flag for Follow Up";
  }

  toggleFlagged(): void {
    const initial = this.isFlagged();
    const entityFormGroupFlagged = this.entityFormGroup.get("flagged");
    entityFormGroupFlagged.setValue(`${!initial}`);
    this.entityFormGroup.markAsDirty();
  }

  signed = false;

  toggleSigned(): void {
    this.signed = !this.signed;
    this.entityFormGroup.patchValue({"signedBy": this.signed})
    this.entityFormGroup.markAsDirty();
  }

  certifiedInfo(): string {
    const signedBy = this.targetSubject.getValue()?.signedBy;
    const signedByDate = this.targetSubject.getValue()?.signedByDate;
    const loginMeta = this.cookieMgr.getCookieLoginMeta();

    let signer = "";
    let date = "";

    if(signedBy?.id) {
      signer = `${signedBy.firstName} ${signedBy.lastName}`;
    } else {
      signer = `${loginMeta.user.firstName} ${loginMeta.user.lastName}`;
    }

    if(signedByDate) {
      date = ` on ${signedByDate}`
    }

    return `Certified by ${signer}${date}`;
  }
}
