
export enum EntityType {
  client = 1,
  clientProject = 2,
  emailUnsubscribe = 3,
  organization = 4,
  organizationMembership = 5,
  emailHistory = 6,
  clientProjectPermit = 7,
  clientContact = 8,
  basicSurvey = 9,
  unused_le = 10,
  unused_p = 11,
  unused_g = 12,
  user = 13,
  unused_ct = 14,
  unused_s = 15,
}
