import {BaseEntity} from "../base/base-entity";

export class ClientProjectPermit extends BaseEntity {
  clientId: number;
  projectId: number;

  type: string;
  startValid: string;
  expire: string;
  authorizationNo: string;


  constructor(params: any = {}) {
    super(params);
    // console.log("--- constructor")
    // console.dir(params);
    // console.log("--- constructor")
    this.type = params.type;
    this.startValid = params.startValid;
    this.expire = params.expire;
    this.authorizationNo = params.authorizationNo;
  }
}
