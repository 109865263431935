import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";
import {EntityService} from "../base/base-service";
import {ClientContact} from "../domain/client-contact";
import {PageService} from "../app-list/app-list-base.component";


@Injectable()
export class ClientContactService extends EntityService<ClientContact> implements PageService<ClientContact>{

  constructor(http: HttpClient, cookieMgr: CookieMgr) {
    super(http, cookieMgr);
  }

  getUrl(): string {
    return "/v2/contact";
  }

  instanceOfEntity(params: any): ClientContact {
    return new ClientContact(params);
  }

  getPostBody(v: ClientContact): any {
    const result = {id: v.id, created: v.created, updated: v.updated,
      clientId: v.clientId,
      firstName: v.firstName,
      lastName: v.lastName,
      email: v.email,
      cellPhone: v.cellPhone,
      memo: v.memo,
    };

    if(v.client) {
      result["client"] = {
        id: v.client?.id,
        name: v.client?.name
      };
    }

    if(v.user) {
      result["user"] = {
        id: v.user?.id
      };
    }

    return result;
  }

}
