import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppNavComponent} from './app-nav/app-nav.component';
import {AppLoginComponent} from './app-login/app-login.component';
import {ExtraOptions, RouterModule, Routes} from "@angular/router";
import {AppHomeComponent} from './app-home/app-home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginService} from "./app-login/login.service";
import {HttpClientModule} from "@angular/common/http";
import {CookieMgr} from "./util/cookie-mgr";
import {AppClientComponent} from './app-client/app-client.component';
import {ClientService} from "./app-client/client.service";
import {AppClientListComponent} from './app-client-list/app-client-list.component';
import {CommonModule} from '@angular/common';
import {AppProjectListComponent} from './app-project-list/app-project-list.component';
import {ClientProjectService} from "./app-project/client-project-service";
import { AppProjectComponent } from './app-project/app-project.component';
import { AppProjectPermitComponent } from './app-project-permit/app-project-permit.component';
import { AppProjectPermitListComponent } from './app-project-permit-list/app-project-permit-list.component';
import {ClientProjectPermitService} from "./app-project-permit/client-project-permit-service";
import { AppInspectionListComponent } from './app-inspection-list/app-inspection-list.component';
import { AppInspectionComponent } from './app-inspection/app-inspection.component';
import {InspectionService} from "./app-inspection/inspection-service";
import { ValidatorMsgComponent } from './validator-msg/validator-msg.component';
import { AppClientContactListComponent } from './app-client-contact-list/app-client-contact-list.component';
import { AppClientContactComponent } from './app-client-contact/app-client-contact.component';
import {ClientContactService} from "./app-client-contact/client-contact-service";
import { AppInspectionReportComponent } from './app-inspection-report/app-inspection-report.component';
import {InspectionReportService} from "./app-inspection-report/inspection-report-service";
import { AppFlashMsgComponent } from './app-flash-msg/app-flash-msg.component';
import { AppBcsButtonGroupComponent } from './app-bcs-button-group/app-bcs-button-group.component';
import {AppFileGroupComponent } from './app-file-group/app-file-group.component';
import {FileGroupService} from "./app-file-group/file-group-service";
import { AppArchivableRecordHeaderComponent } from './app-archivable-record-header/app-archivable-record-header.component';
import { AppListFilterComponent } from './app-list-filter/app-list-filter.component';
import { AppErrorLoadComponent } from './app-error-load/app-error-load.component';
import { AppListPagerComponent } from './app-list-pager/app-list-pager.component';
import { AppFilterPageListComponent } from './app-filter-page-list/app-filter-page-list.component';
import { AppFilterPageListCardComponent } from './app-filter-page-list-card/app-filter-page-list-card.component';
import { AppFilterPageListCardContainerComponent } from './app-filter-page-list-card-container/app-filter-page-list-card-container.component';


const appRoutes: Routes = [

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', pathMatch: 'full', component: AppHomeComponent },

  { path: 'client/list', component: AppClientListComponent, data: {} },
  { path: 'client', component: AppClientComponent, data: {} },
  { path: 'client/:id', component: AppClientComponent, data: {} },

  { path: 'contact/list', component: AppClientContactListComponent, data: {} },
  { path: 'contact', component: AppClientContactComponent, data: {} },
  { path: 'contact/:id', component: AppClientContactComponent, data: {} },

  { path: 'organizational-contact/list', component: AppClientContactListComponent, data: {} },
  { path: 'organizational-contact', component: AppClientContactComponent, data: {} },
  { path: 'organizational-contact/:id', component: AppClientContactComponent, data: {} },

  { path: 'inspection/list', component: AppInspectionListComponent, data: {} },
  { path: 'inspection', component: AppInspectionComponent, data: {} },
  { path: 'inspection/:id', component: AppInspectionComponent, data: {} },

  // the subject-id is for the inspection under consideration
  { path: 'inspection/report/subject/:id', component: AppInspectionReportComponent, data: {} },

  { path: 'permit/list', component: AppProjectPermitListComponent, data: {} },
  { path: 'permit', component: AppProjectPermitComponent, data: {} },
  { path: 'permit/:id', component: AppProjectPermitComponent, data: {} },

  { path: 'project/list', component: AppProjectListComponent, data: {} },
  { path: 'project', component: AppProjectComponent, data: {} },
  { path: 'project/:id', component: AppProjectComponent, data: {} },

  // { path: 'asset', pathMatch: 'full', component: AssetComponent },
  // { path: 'asset/list', pathMatch: 'full', component: AssetListComponent },
  // { path: 'asset/new', pathMatch: 'full', component: AssetEditComponent },
  // { path: 'asset/edit/:id', pathMatch: 'full', component: AssetEditComponent },
  //
  // { path: 'inspection', pathMatch: 'full', component: InspectionHomeComponent },
  // { path: 'inspection/list', pathMatch: 'full', component: InspectionListComponent },
  // { path: 'inspection/new', pathMatch: 'full', component: InspectionAddEditComponent },
  // { path: 'inspection/edit/:id', pathMatch: 'full', component: InspectionAddEditComponent },
  //
  // { path: 'admin', pathMatch: 'full', component: AdminHomeComponent },
  //
  // { path: 'user', pathMatch: 'full', component: UserHomeComponent },
  // { path: 'user/list', pathMatch: 'full', component: UserListComponent },
  // { path: 'user/new', pathMatch: 'full', component: UserEditComponent },
  // { path: 'user/edit/:id', pathMatch: 'full', component: UserEditComponent },
];

const extraOptions: ExtraOptions = {
  useHash: true
};


@NgModule({
  declarations: [
    AppComponent,
    AppNavComponent,
    // AppLoginComponent,
    AppHomeComponent,
    AppLoginComponent,
    AppClientComponent,
    AppClientListComponent,
    AppProjectListComponent,
    AppProjectComponent,
    AppProjectPermitComponent,
    AppProjectPermitListComponent,
    AppInspectionListComponent,
    AppInspectionComponent,
    ValidatorMsgComponent,
    AppClientContactListComponent,
    AppClientContactComponent,
    AppInspectionReportComponent,
    AppFlashMsgComponent,
    AppBcsButtonGroupComponent,
    AppFileGroupComponent,
    AppArchivableRecordHeaderComponent,
    AppListFilterComponent,
    AppErrorLoadComponent,
    AppListPagerComponent,
    AppFilterPageListComponent,
    AppFilterPageListCardComponent,
    AppFilterPageListCardContainerComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, extraOptions)
  ],
  providers: [
    ClientService,
    ClientContactService,
    ClientProjectService,
    ClientProjectPermitService,
    CookieMgr,
    FileGroupService,
    HttpClientModule,
    // Location,
    InspectionService,
    InspectionReportService,
    LoginService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
