import {Component, Input, OnInit} from '@angular/core';
import {BaseAddEditComponent} from "../base/base-add-edit.component";

@Component({
  selector: 'validator-msg',
  templateUrl: './validator-msg.component.html',
  styleUrls: ['./validator-msg.component.css']
})
export class ValidatorMsgComponent implements OnInit {
  @Input()
  component: BaseAddEditComponent<any>;

  @Input()
  formElementName: string;

  constructor() { }

  ngOnInit(): void {
    // console.log(`component: ${this.component}`);
    // console.log(`formElementName: ${this.formElementName}`);
  }

  isReady(): boolean {
    const result = !!this.component && !!this.formElementName;
    // console.log(`isReady: ${result}`);
    // console.log(`component: ${this.component}`);
    // console.log(`formElementName: ${this.formElementName}`);
    return result;
  }
}
