import {Injectable} from "@angular/core";

@Injectable()
export class CookieMgr {
  static COOKIE_TSV2_AUTHORIZATION = "ts-v2-authorization";
  static COOKIE_TSV2_LOGIN_META = "ts-v2-login-meta";

  public setCookie(name: string, value: string): void {
    document.cookie = `${name}=${value}`;
  }

  public getCookie(name: string): string {
    const result =
      document.cookie
        .split(';')
        .map(cookie => cookie.trim())
        .filter(cookie => {
          return cookie.split('=')[0] === name;
        });

    if(result && result.length > 0) {
      return result[0].split('=')[1];
    }else {
      return null;
    }
  }

  public setAuthorizationCookie(value: string): void {
    this.setCookie(CookieMgr.COOKIE_TSV2_AUTHORIZATION, value);
  }

  public getAuthorizationCookie(): string {
    return this.getCookie(CookieMgr.COOKIE_TSV2_AUTHORIZATION);
  }

  public setCookieLoginMeta(organization: string): void {
    this.setCookie(CookieMgr.COOKIE_TSV2_LOGIN_META, organization);
  }

  public getCookieLoginMeta(): any {
    const value = this.getCookie(CookieMgr.COOKIE_TSV2_LOGIN_META);
    return JSON.parse(value);
  }

  public deleteCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
}
