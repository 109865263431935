<div class="card rounded-0 h-100" *ngIf="!this.hidden">
  <div class="card-body">
    <h5>
      <span>
        <ng-container [ngTemplateOutlet]="ngtTitle" [ngTemplateOutletContext]="{element: element}"></ng-container>
      </span>
    </h5>

    <app-filter-page-list-card [element]="element"></app-filter-page-list-card>

    <hr/>

    <div>
      <ng-container [ngTemplateOutlet]="ngtButton" [ngTemplateOutletContext]="{element: this.element, hideable: this}"></ng-container>
    </div>

  </div>
</div>
