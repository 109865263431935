import {BaseEntity} from "../base/base-entity";
import {Client} from "./client";
import {ClientProjectPermit} from "./client-project-permit";
import {NamedBaseEntity} from "./named-base-entity";
import {ArchivableEntity} from "./archivable-entity";

export class ClientProject extends BaseEntity implements ArchivableEntity, NamedBaseEntity {
  clientId: number;
  name: string;
  coordLon: string;
  coordLat: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  receivingWaterOutfall: boolean;
  phase: string;
  acresDisturbed: number;
  client: Client;
  permits: ClientProjectPermit[];
  archived: boolean;

  constructor(params: any = {}) {
    super(params);

    this.name = params.name;
    this.archived = params.archived;

    if(params.location) {
      this.coordLon = params.location.lon
      this.coordLat = params.location.lat
      this.address1 = params.location.address1
      this.address2 = params.location.address2
      this.city = params.location.city
      this.state = params.location.state
      this.zip = params.location.zip
    }

    if(params.permits) {
      this.permits = params.permits;
    }

    if(params.extended) {
      this.receivingWaterOutfall = params.extended.receivingWaterOutfall
      this.phase = params.extended.phase
      this.acresDisturbed = params.extended.acresDisturbed
    }

    if(params.client && params.client.id) {
      this.clientId = params.client.id;
      this.client = new Client({"id": params.client.id, "name": params.client.name});
    }

    if(params.permits) {
      this.permits = params.permits.map(permit => {
        return new ClientProjectPermit(permit);
      });
    }
  }
}
