import {Observable, Observer} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";
import {Client} from "../domain/client";
import {PageCriteria, PageSort} from "./page-criteria";
import {ClientProject} from "../domain/client-project";

export class DataContext {
  client: Client;
  project: ClientProject;
}

export class EntityPage<V> {
  data: V[];

  page: {
    count: number
    current: number
    first: boolean
    last: boolean
    size: number,
    context: DataContext
  };

  criteria: PageCriteria;
  sort: PageSort;
}

export abstract class EntityService<V> {

  protected constructor(protected http: HttpClient, protected cookieMgr: CookieMgr) {}

  protected getUrl(): string {
    // TODO - Remove this safely - not a sensible default
    return "/v2/client"
  }

  protected getRequestOptions(pageSize?: number, page?: number, criteria?: PageCriteria): any {
    // TODO - Refactor - Authorization handling should be a small method ; its copy pasted a bunch now
    const authorization = this.cookieMgr.getAuthorizationCookie();

    if (!authorization) {
      throw new Error("No Authorization available");
    }

    const result = {
      headers: new HttpHeaders().set('Authorization', authorization ? authorization : ''),
    };

    let httpParams = new HttpParams();

    // TODO - Refactor - this seems to suit List components

    if (!!pageSize && !!page) {
      httpParams = httpParams.set('pageSize', `${pageSize}`).set('page', `${page}`);
    }

    if (criteria) {
      if (criteria.clientId) {
        httpParams = httpParams.set('clientId', `${criteria.clientId}`);
      }

      if (criteria.projectId) {
        httpParams = httpParams.set('projectId', `${criteria.projectId}`);
      }

      if (criteria.desc) {
        httpParams = httpParams.set('desc', `${criteria.desc}`);
      }

      if (criteria.createLow) {
        httpParams = httpParams.set('createLow', `${criteria.createLow}`);
      }

      if (criteria.createHigh) {
        httpParams = httpParams.set('createHigh', `${criteria.createHigh}`);
      }

      if (criteria.updateLow) {
        httpParams = httpParams.set('updateLow', `${criteria.updateLow}`);
      }

      if (criteria.updateHigh) {
        httpParams = httpParams.set('updateHigh', `${criteria.updateHigh}`);
      }

      if (criteria.archived) {
        httpParams = httpParams.set('archived', `${criteria.archived}`);
      }

      if (criteria.sortField !== undefined) {
        httpParams = httpParams.set('sortField', `${criteria.sortField}`);
      }

      if (criteria.sortDirection !== undefined) {
        httpParams = httpParams.set('sortDirection', `${criteria.sortDirection}`);
      }

    }

    result["params"] = httpParams

    return result;
  }

  // getById (id: number): Observable<V> {
  getById(id: number | string): Observable<V> {
    const options = this.getRequestOptions();
    const url = `${this.getUrl()}/${id}`;

    return new Observable<V>((observer: Observer<V>) => {
      this.http
        .get<EntityPage<V>>(url, options)
        // .subscribe((o:HttpEvent<EntityPage<V>>) => {
        .subscribe((o: any) => {
          observer.next(this.instanceOfEntity(o.data[0]));
        }, (error) => {
          observer.error({
            "status": error.status,
            "errors": error.error
          });
        });
    });
  }

  getPage(pageSize: number, page: number, criteria?: PageCriteria): Observable<EntityPage<V>> {
    const options = this.getRequestOptions(pageSize, page, criteria);
    const url = `${this.getUrl()}`;

    return new Observable<EntityPage<V>>((observer: Observer<EntityPage<V>>) => {
      this.http
        .get<EntityPage<V>>(url, options)
        .subscribe((o: any) => {
          observer.next(o);
        }, (error) => {
          observer.error({
            "status": error.status,
            "errors": error.error
          });
        });
    });
  }

  public persist(v: V): Observable<V> {
    const body = this.getPostBody(v);
    const options = this.getRequestOptions();

    return new Observable((subscriber => {
      this.http
        .post(this.getUrl(), body, options)
        .subscribe((response: any) => {
          subscriber.next(response);
          subscriber.complete();
        }, (e) => {
          subscriber.error({"message": "Failed to persist data."});
        });
    }));
  }

  abstract getPostBody(v: V): any;

  abstract instanceOfEntity(params: any): V;

}
