import {SortDirection, SortField} from "../app-list-filter/app-list-filter.component";

export class PageSort {
  direction: SortDirection;
  field: SortField;
}

export class PageCriteria {
    public static pageSizeDefault = 12;
    public static pageDefault = 1;

    desc: string;
    archived: boolean;
    createLow: string;
    createHigh: string;
    updateLow: string;
    updateHigh: string;
    clientId: string;
    projectId: string;
    pageSize: number;
    page: number;

    sortDirection: SortDirection;
    sortField: SortField;

    public static fromParams(params: any, override: any = {}): PageCriteria {
        const result = new PageCriteria();

        result.archived = params["archived"] || false;
        result.clientId = params["clientId"];
        result.desc = params["desc"];
        result.projectId = params["projectId"];
        result.createLow = params["createLow"];
        result.createHigh = params["createHigh"];
        result.updateLow = params["updateLow"];
        result.updateHigh = params["updateHigh"];
        result.pageSize = params["pageSize"] ? params["pageSize"] : PageCriteria.pageSizeDefault;
        result.page = params["page"] ? params["page"] : PageCriteria.pageDefault;

        if(override && 'sortField' in override) {
          result.sortField = override["sortField"]
        }else {
          result.sortField = params["sortField"] ? params["sortField"] : SortField.created;
        }

        if(override && 'sortDirection' in override) {
          result.sortDirection = override["sortDirection"]
        }else {
          result.sortDirection = params["sortDirection"] ? params["sortDirection"] : SortDirection.descending;
        }

        return result;
    }
}
