<div class="card rounded-0">
<!--  {{ this.entityFormGroup.pristine }} <br />-->
<!--  {{ this.entityFormGroup.dirty }} <br />-->
<!--  {{ this.entityFormGroup.status }} <br />-->
  <div class="card-body" *ngIf="this.isEntityNew()">
    <span>Files can be attached after the entity has been saved</span>
  </div>

  <div class="card-body" *ngIf="this.isLoading()">
    <img src="assets/spinner.gif" style="height:1.25em;" />
    <span>&nbsp;</span>
    <span>Fetching File Group information</span>
  </div>

  <div class="card-body" *ngIf="!this.isLoading() && !this.isEntityNew()">
    <form [formGroup]="entityFormGroup" autocomplete="off">

      <div class="input-group">
<!--        <label for="description">File Group Description</label>-->
        <input type="text"
               class="form-control"
               id="description"
               name="description"
               placeholder="{{ this.fileGroupSupportSubject?.getValue().descriptionDefault }}"
               maxlength="255"
               formControlName="description"
        >
        <div class="input-group-append" *ngIf="!this.entityFormGroup.pristine">
          <button class="btn btn-outline-secondary"
                  type="button" (click)="this.clickCancelDescription()">Cancel</button>
        </div>

        <div class="input-group-append">
          <button class="btn btn-outline-success"
                  type="button"
                  [disabled]="this.entityFormGroup.pristine"
                  (click)="this.clickSaveDescription()">Save</button>
        </div>
      </div>
    </form>

    <div class="text-right" style="padding-top:0.75em;" *ngIf="this.isMainCommandRowVisible()">
      <span>
        <button type="button"
                class="btn btn-outline-secondary"
                title="Add files to File Group"
                (click)="clickAddFiles()">
          <span class="oi oi-data-transfer-upload"></span>
          Add Files
        </button>
      </span>
      <span>&nbsp;</span>
      <span>
<!--                [ngClass]="{'disabled': this.fileGroupSubject === null || this.fileGroupSubject?.value?.files === null || this.fileGroupSubject?.value?.files?.length === 0}"-->
<!--                [disabled]="this.fileGroupSubject === null || this.fileGroupSubject?.value?.files === null || this.fileGroupSubject?.value?.files?.length === 0"-->
        <button type="button"
                class="btn btn-outline-success"
                title="View file list"
                (click)="clickViewFiles()">
          <span class="oi oi-list"></span>
          View File List
        </button>
      </span>

    </div>

    <div *ngIf="this.isFileUploadVisible()" class="table-responsive">
      <input type="file"
             class="custom-file-input"
             id="fileUpload"
             multiple
             #fileUpload
             (change)="change($event.target.files)" accept="{{ accept }}"
             (waiting)="waiting($event)"
             (progress)="progress($event)"
      />

      <div class="table-responsive" style="overflow-x: scroll;">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Filename</th>
              <th scope="col">Bytes</th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row" *ngFor="let file of files">
              <td class="file-group-entry">{{ file.name }}</td>
              <td class="file-group-entry">{{ file.size.toLocaleString() }}</td>
            </tr>

            <tr scope="row">
              <td></td>
              <td>
                <ng-container *ngIf="(this.labelCountBytesSelectedFiles | async) as labelCountBytes">
                  {{ labelCountBytes }}
                </ng-container>
              <td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <ng-container *ngIf="(this.labelUploadError | async) as labelUploadError">
          {{ labelUploadError }}
        </ng-container>
      </div>

      <div class="text-right">

        <button class="btn btn-outline-primary" (click)="clickSelectFiles($event)">
          <span class="oi oi-cloud-upload"></span>
          <span>&nbsp;</span>
          <span>Select Files</span>
        </button>

        <span>&nbsp;</span>

        <button type="button"
                class="btn btn-outline-success"
                (click)="clickCancel()">
          <ng-container *ngIf="(this.labelCancelCloseValue | async) as labelCC">
            {{ labelCC }}
          </ng-container>
        </button>

        <span>&nbsp;</span>

        <button type="button"
                class="btn btn-outline-success"
                (click)="clickUpload()"
                [disabled]="this.isUploadButtonDisabled()"
          >
          <span>Upload</span>
        </button>

      </div>
    </div><!-- end of this.isFileUploadVisible -->

    <div style="padding-top:0.75em;" class="" *ngIf="this.isViewExistingFilesVisible()">

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Filename</th>
            <th scope="col">Bytes</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>

        <!-- These are files that exist, not being uploaded -->
        <tr scope="row" *ngFor="let file of this.fileGroupSubject?.value?.files">
          <td class="file-group-entry">{{ file.name }}</td>
          <td class="file-group-entry">{{ file.sizeInBytes.toLocaleString() }}</td>
          <td>

            <span *ngIf="isDownloadDisabled(file); else elseNotUpdated">
              <img src="assets/spinner-fd.gif" style="height:3em; width:3em;">
            </span>

            <ng-template #elseNotUpdated>
              <span>
                <a type="button"
                   class="btn btn-outline-success"
                   (click)="clickDownload(fileGroupSubject.getValue().id, file.id)"
                   [class.disabled]="isDownloadDisabled(file)"
                >
                    <span class="oi oi-cloud-download"></span>
                </a>
              </span>
            </ng-template>


          </td>
        </tr>
        </tbody>
      </table>
      <div class="text-right">
        <ng-container *ngIf="(this.labelCountBytesExistingFiles | async) as labelCountBytes">
          {{ labelCountBytes }}
        </ng-container>
      </div>
      <div class="text-right">
        <button type="button"
                class="btn btn-outline-success"
                (click)="clickViewFilesRefresh()">
          Refresh
        </button>
        <span>&nbsp;</span>
        <button type="button"
                class="btn btn-outline-success"
                (click)="clickViewFilesClose()">
          Close
        </button>
      </div>

    </div>

  </div>

</div>
