<div class="top-pad"></div>

<div class="container-fluid">

  <div class="row mt-4">

    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 offset-sm-0 offset-md-1 offset-lg-1 offset-xl-1">
      <div class="card rounded-0">
        <div class="card-body">
          <div>
            <h5>{{ this.organization().name }}</h5>
          </div>

          <div class="card-subtitle mb-2 text-muted">
            <span>Logged in as {{ this.loggedInUsername() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
