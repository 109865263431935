
export abstract class BaseEntity {
  id: number;

  // created: Date;
  // updated: Date;
  created: {
    date: Date;
    user: {
      id: number,
      username: string,
    };
  };
  updated: {
    date: Date,
    user: {
      id: number,
      username: string,
    };
  };

  constructor(params: any = {}) {
    this.update(params);
  }

  update(next: BaseEntity) {
    this.id = next.id;
    this.created = next.created ? next.created : this.created;
    this.updated = next.updated ? next.updated : this.updated;
  }

}
