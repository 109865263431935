import {Component, Input, OnInit} from '@angular/core';
import {BaseEntity} from "../base/base-entity";
import {ArchivableEntity} from "../domain/archivable-entity";

@Component({
  selector: 'app-filter-page-list-card',
  templateUrl: './app-filter-page-list-card.component.html',
  styleUrls: ['./app-filter-page-list-card.component.css']
})
export class AppFilterPageListCardComponent implements OnInit {
  @Input()
  element: BaseEntity | ArchivableEntity;

  constructor() { }

  ngOnInit(): void {
  }

  isArchived(): boolean {
    return (this.element as ArchivableEntity).archived;
  }

  hackDate(subject: Date): string {
    if(subject) {
      return `${subject}`.split(' ')[0];
    }else {
      return null;
    }
  }
}
