import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ClientProjectService} from "../app-project/client-project-service";
import {BaseEntity} from "../base/base-entity";
import {AppListViewBase} from "../app-list/app-list-view-base";
import {ArchivableEntity} from "../domain/archivable-entity";
import {ClientProject} from "../domain/client-project";
import {SortDirection, SortField} from "../app-list-filter/app-list-filter.component";
import {Hideable} from "../app-filter-page-list-card-container/app-filter-page-list-card-container.component";


@Component({
  selector: 'app-project-list',
  templateUrl: './app-project-list.component.html',
  styleUrls: ['./app-project-list.component.css']
})
export class AppProjectListComponent extends AppListViewBase {

  constructor(protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router,
              protected clientProjectService: ClientProjectService) {

    super(activatedRoute, location, router);
  }

  clickCreateQueryParams(): Record<string, string> {
    return {"clientId": this.pageCriteria.getValue()?.clientId};
  }

  clickPermits(entity: BaseEntity): void {
    this.router
      .navigateByUrl(`/permit/list?projectId=${entity.id}`)
      .then((z) => {});
  }

  clickInspections(entity: BaseEntity): void {
    this.router
      .navigateByUrl(`/inspection/list?projectId=${entity.id}`)
      .then((z) => {});
  }

  // TODO - Refactor to "archivable base"
  clickArchiveToggle(entity: ArchivableEntity, hideable: Hideable): void {
    entity.archived = !entity.archived;

    this
      .getEntityService()
      .persist(entity as ClientProject)
      .subscribe((obj) => {
        hideable.hide();
      });
  }

  public entityName(): string {
    return "project";
  }

  public getEntityService(): ClientProjectService {
    return this.clientProjectService;
  }

  labelFilterFieldText(): string  {
    return "Project Name";
  }

  public getDefaultSortField(): SortField {
    return SortField.descriptiveText;
  }

  public getDefaultSortDirection(): SortDirection {
    return SortDirection.ascending;
  }
}
