<div class="container-fluid top-pad" >

  <div class="row top-pad">

    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <div>
            <h5>{{ this.entityNameCapitalized() }}</h5>
          </div>

          <div class="card-subtitle mb-2 text-muted">
            <span>Manage a {{ this.entityNameCapitalized() }} record for a client</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row sub-pad" *ngIf="this.flashMessage">
    <div class="col-lg-6 col-sm-12">
      <div class="alert alert-danger" role="alert" *ngIf="this.flashMessage.isError">
        <h4 class="alert-heading">{{ this.flashMessage.messageHead }}</h4>
        <p>{{ this.flashMessage.messageSub }}</p>
        <hr>
        <div class="text-right">
          <a class="btn btn-outline-info my-2 my-sm-0" data-dismiss="alert">Dismiss</a>
        </div>
      </div>
    </div>
  </div>


  <div class="row sub-pad">
    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <form [formGroup]="entityFormGroup" autocomplete="off">
            <input type="hidden" value="" id="id" name="id" />

            <div class="form-group">
              <label for="firstName">First Name</label>
              <input type="text"
                     class="form-control"
                     id="firstName"
                     name="firstName"
                     placeholder="First Name"
                     formControlName="firstName"
                     required
                     [ngClass]="{'invalid': !this.isValid('firstName')}">
            </div>

            <validator-msg [component]="this" formElementName="firstName"></validator-msg>

            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input type="text"
                     class="form-control"
                     id="lastName"
                     name="lastName"
                     placeholder="Last Name"
                     formControlName="lastName"
                     required
                     [ngClass]="{'invalid': !this.isValid('lastName')}">
            </div>

            <validator-msg [component]="this" formElementName="lastName"></validator-msg>

            <div class="form-group">
              <label for="lastName">Email</label>
              <input type="text"
                     class="form-control"
                     id="email"
                     name="email"
                     placeholder="Email"
                     formControlName="email"
                     required
                     [ngClass]="{'invalid': !this.isValid('email')}">
            </div>

            <validator-msg [component]="this" formElementName="email"></validator-msg>

            <div class="form-group">
              <label for="lastName">Cell</label>
              <input type="text"
                     class="form-control"
                     id="cellPhone"
                     name="cellPhone"
                     placeholder="Cell"
                     formControlName="cellPhone"
                     [ngClass]="{'invalid': !this.isValid('cellPhone')}">
            </div>

            <validator-msg [component]="this" formElementName="cellPhone"></validator-msg>

            <div class="form-group">
              <label for="lastName">Memo</label>
              <input type="text"
                     class="form-control"
                     id="memo"
                     name="memo"
                     placeholder="Memo"
                     formControlName="memo"
                     [ngClass]="{'invalid': !this.isValid('memo')}">
            </div>

            <validator-msg [component]="this" formElementName="memo"></validator-msg>
            <app-flash-msg [flashMessage]="this.flashMessage"></app-flash-msg>
            <app-bcs-button-group [backCancelSave]="this"></app-bcs-button-group>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
