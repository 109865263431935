import {BaseEntity} from "../base/base-entity";
import {ClientProject} from "./client-project";
import {AppUser} from "./app-user";
import {FileGroupSupport} from "./file-group-support";
import {EntityType} from "./entity-type";

export class ProjectInspection extends BaseEntity implements FileGroupSupport {
  project: ClientProject;
  currentPhase: string;
  currentWeather: string;
  dateOf = Date;
  flagged: boolean;
  signedBy: AppUser;
  signedByDate: Date;

  // # Section 1 - "Sediment and Erosion Control"
  // # Are all slopes and disturbed area not actively being worked properly stabilized?
  question1: boolean;

  // # Are natural resource areas (e.g. streams, wetlands, mature trees, etc.) protected with barriers or similar BMPs?
  question2: boolean;

  // # Are perimeter controls and sediment barriers adequately installed (keyed into substrate) and maintained?
  question3: boolean;

  // # Are discharge points and receiving waters free of sediment deposits?
  question4: boolean;

  // # Are storm drain inlets properly protected?
  question5: boolean;

  // # Are paved areas free of significant sediment tracking and debris?
  question6: boolean;

  // # Are site entrances & exits stabilized with rock or other appropriate means?
  question7: boolean;

  // # Are Structural BMPs such as diversion berms, weirs, spillways, standpipes, etc. in place and functioning?
  question8: boolean;

  // # For sites containing common drainage of 10 ACs+,
  // # is there a basin in place & functioning at engineered capacity levels?
  question9: boolean;

  // # Are the appropriate dust control measures being taken during dry or arid time frames?
  question10: boolean;

  // # Are non-storm water discharges (e.g. wash water, dewatering) properly controlled?
  question11: boolean;

  // # Sediment and Erosion Control Notes
  erosionControlNotes: string;

  // # Section 2 - SWPPP Review (TJ titled House Keeping Measures)

  // # Are vehicle & equipment fueling & maintenance areas clean & free of spills, leaks, or any other deleterious materials?
  question12: boolean;

  // # Are washout facilities (e.g., paint, stucco, concrete) available, clearly marked and maintained?
  question13: boolean;

  // # Are materials that are potential storm water contaminants being stored properly?
  question14: boolean;

  // # Is trash/litter from work areas collected and placed in dumpsters?
  question15: boolean;

  // # Is the current NPDES General Permit 2 included in the SWPPP?
  question16: boolean;

  // # Are all applicable Contractor Certifications signed and included in the SWPPP?
  question17: boolean;

  // # Is the Construction Schedule being kept up to date noting all major activity/construction/stabilization changes?
  question18: boolean;

  // # Is the SWPPP plan being kept current reflecting changes to controls and surrounding site features as they occur?
  question19: boolean;

  // # Media content link (ie Google Drive share URL)
  urlMedia: string;

  entityId: number;
  entityType = EntityType.basicSurvey;
  fileGroupId?: number;
  projectId?: number;
  descriptionDefault: string = "default";

  constructor(params: any = {}) {
    super(params);
    this.project = new ClientProject(params.project);
    this.currentPhase = params.currentPhase;
    this.currentWeather = params.currentWeather;
    this.dateOf = params.dateOf;
    this.erosionControlNotes = params.erosionControlNotes;
    this.flagged = params.flagged;
    this.question1 = params.question1;
    this.question2 = params.question2;
    this.question3 = params.question3;
    this.question4 = params.question4;
    this.question5 = params.question5;
    this.question6 = params.question6;
    this.question7 = params.question7;
    this.question8 = params.question8;
    this.question9 = params.question9;
    this.question10 = params.question10;
    this.question11 = params.question11;
    this.question12 = params.question12;
    this.question13 = params.question13;
    this.question14 = params.question14;
    this.question15 = params.question15;
    this.question16 = params.question16;
    this.question17 = params.question17;
    this.question18 = params.question18;
    this.question19 = params.question19;
    this.signedBy = params.signedBy ? new AppUser(params.signedBy.user) : null;
    this.signedByDate = params.signedBy?.date;
    this.urlMedia = params.urlMedia;

    this.descriptionDefault = `Inspection Files`
  }

}
