import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {NamedBaseEntity} from "../domain/named-base-entity";
import {ArchivableEntity} from "../domain/archivable-entity";
import {ClientProjectService} from "../app-project/client-project-service";
import {ClientProject} from "../domain/client-project";

@Component({
  selector: 'app-archivable-record-header',
  templateUrl: './app-archivable-record-header.component.html',
  styleUrls: ['./app-archivable-record-header.component.css']
})
export class AppArchivableRecordHeaderComponent implements OnInit {
  @Input()
  record: ArchivableEntity;

  visibleContent = true;
  visibleConfirmation = false;
  visibleWip = false;
  visibleConclusion = false;

  conclusion: string = "";

  constructor(protected clientProjectService: ClientProjectService) {
  }

  ngOnInit(): void {
  }

  clickArchive(): void {
    this.visibleContent = false;
    this.visibleConfirmation = true;
  }

  clickConfirmationNo(): void {
    this.visibleConfirmation = false;
    this.visibleContent = true;
  }

  clickConfirmationYes(): void {
    this.record.archived = true;

    this.clientProjectService.persist(this.record as ClientProject).subscribe((obj) => {
      this.visibleConfirmation = false;
      this.visibleWip = true;

      this.timerTransition((res, rej) => {
        this.conclusion = "Archive succeeded";
        this.visibleWip = false;
        this.visibleConfirmation = false;
        this.visibleConclusion = true;
        res();
      }).then((result) => {
        this.timerTransition((res, rej) => {
          this.visibleConclusion = false;
          this.visibleContent = true;
        }, 1500)
      });
    }, (obj) => {
      this.timerTransition((res, rej) => {
        this.conclusion = "Archive failed";
        this.visibleConclusion = true;

        res();
      }).then((result) => {
        this.visibleConclusion = false;
        this.visibleContent = true;
      });
    });
  }

  timerTransition(onTimeout: (res, rej) => void, timeout: number = 500): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        onTimeout(resolve, reject);
      }, timeout);
    });
  }
}
