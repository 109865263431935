<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">

    <div class="mb-1">
      <div class="input-group">
        <input type="text"
               class="form-control"
               (keypress)="handleKeyPress($event)"
               [(ngModel)]="this.desc"
               [disabled]="!this.allowTextCriteria">

        <div class="input-group-append">

          <button class="btn btn-outline-info"
                  data-toggle="collapse"
                  data-target="#collapsableFilterFields"
                  type="button">
            <span>
              <span class="oi oi-magnifying-glass"></span>
            </span>
          </button>

          <button class="btn btn-outline-info"
                  data-toggle="collapse"
                  data-target="#collapsableSortFields"
                  type="button">
            <span class="oi" [ngClass]="{'oi-sort-ascending' : this.sortDirection === this.sortDirectionEnum.ascending, 'oi-sort-descending' : this.sortDirection === this.sortDirectionEnum.descending }"> </span>

          </button>

          <button type="button" class="btn btn-outline-info" (click)="clickFilterApply()">Search</button>

        </div>
      </div>
    </div>

    <div class="collapse collapsable mb-1" id="collapsableSortFields">
      <div class="card card-body">
        <div>
          <div class="btn-group my-2" role="group">
            <div class="btn-group" role="group">
              <button id="sortField"
                      type="button"
                      class="btn btn-outline-info dropdown-toggle"
                      data-toggle="dropdown">
                {{ this.sortFieldLabel(this.sortField) }}
              </button>

              <div class="dropdown-menu">
                <button class="dropdown-item btn-outline-info"
                        type="button" *ngFor="let e of sortFieldLabels()" (click)="sortFieldChange(e)">{{ this.sortFieldLabel(e) }}</button>
              </div>
            </div>

            <div class="btn-group" role="group">
              <button id="sortFieldDirection"
                      type="button"
                      class="btn btn-outline-info dropdown-toggle"
                      data-toggle="dropdown">
                {{ this.sortDirectionLabel(this.sortDirection) }}
              </button>

              <div class="dropdown-menu">
                <button class="dropdown-item btn-outline-info"
                        type="button" *ngFor="let e of sortDirectionLabels()" (click)="sortDirectionChange(e)">{{ this.sortDirectionLabel(e) }}</button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <hr/>

          <div class="text-right">
            <button type="button"
                    class="btn btn-outline-secondary mr-1"
                    data-toggle="collapse"
                    data-target="#collapsableSortFields"
                    (click)="clickNavigateSortDirectionClear()">Clear</button>

            <button type="button"
                    class="btn btn-outline-success"
                    data-toggle="collapse"
                    data-target="#collapsableSortFields"
                    type="submit"
                    (click)="clickFilterApply()">Apply</button>

          </div>
        </div>
      </div>
    </div>

    <div class="collapse collapsable mb-1" id="collapsableFilterFields">
      <div class="card card-body">

        <div class="mb-2">
          <div class="form-row">
            <div class="col">
              <label>Created range</label>
            </div>
          </div>

          <div class="form-row mb-1">

            <div class="col mb-1">
              <input type="date"
                     class="form-control"
                     id="filter_create_low"
                     name="filter_create_low"
                     autocomplete="off"
                     [(ngModel)]="createLow">
            </div>

            <div class="col">
              <input type="date"
                     class="form-control"
                     id="filter_crete_high"
                     name="filter_crete_high"
                     autocomplete="off"
                     [(ngModel)]="createHigh">
            </div>
          </div>


          <div class="form-row">
            <div class="col">
              <label>Updated range</label>
            </div>
          </div>

          <div class="form-row">

            <div class="col mb-1">
              <input type="date"
                     class="form-control"
                     id="filter_update_low"
                     name="filter_update_low"
                     autocomplete="off"
                     [(ngModel)]="updateLow">
            </div>

            <div class="col">
              <input type="date"
                     class="form-control"
                     id="filter_update_high"
                     name="filter_update_high"
                     autocomplete="off"
                     [(ngModel)]="updateHigh">
            </div>
          </div>
        </div>

        <div *ngIf="this.archivable">
          <hr/>

          <div class="form-row">
            <div class="col">
              <label for="filter_update_high">Archived</label>
            </div>
          </div>

          <div class="form-row my-2">

            <div class="col">
              <button type="button"
                      class="btn mx-1"
                      [ngClass]="{'btn-outline-secondary': !this.archived, 'btn-success': this.archived}"
                      (click)="clickFilterArchivedToggle()">
                <span>Yes</span>
              </button>
              <button type="button"
                      class="btn mx-1"
                      [ngClass]="{'btn-outline-secondary': this.archived, 'btn-success': !this.archived}"
                      (click)="clickFilterArchivedToggle()">
                <span>No</span>
              </button>
            </div>

          </div>
        </div>

        <div class="text-right">
          <hr/>
          <button type="button"
                  class="btn btn-outline-secondary mr-1"
                  data-toggle="collapse"
                  data-target="#collapsableFilterFields"
                  (click)="clickNavigateFilterClear()">Clear
          </button>

          <button type="button"
                  class="btn btn-outline-success"
                  data-toggle="collapse"
                  data-target="#collapsableFilterFields"
                  type="submit"
                  (click)="clickFilterApply()">Apply
          </button>

        </div>

      </div>
    </div>
  </div>
</div>
