import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";
import {EntityService} from "../base/base-service";
import {ProjectInspection} from "../domain/client-project-inspection";
import {Observable} from "rxjs";
import {PageService} from "../app-list/app-list-base.component";


@Injectable()
export class InspectionService extends EntityService<ProjectInspection> implements PageService<ProjectInspection> {

  constructor(http: HttpClient, cookieMgr: CookieMgr) {
    super(http, cookieMgr);
  }

  getUrl(): string {
    return "/v2/inspection";
  }

  instanceOfEntity(params: any): ProjectInspection {
    return new ProjectInspection(params);
  }

  getPostBody(v: ProjectInspection): any {
    return v;
  }

  clone(inspectionId: number) {
    // TODO - tech debt - copy paste - see report service
    const authorization = this.cookieMgr.getAuthorizationCookie();

    if (!authorization) {
      throw new Error("No Authorization available");
    } else if (!inspectionId) {
      throw new Error("Parameter inspectionId is required");
    }

    const options = {
      headers: new HttpHeaders().set('Authorization', authorization ? authorization : ''),
    };

    // TODO - tech debt - url
    const url = `${this.getUrl()}/clone`;

    return new Observable((observer) => {
      this
        .http
        .post(url, {
          "inspection": {
            "id": inspectionId
          }
        }, options)
        .subscribe((httpResponse:any) => {
          observer.next({});
        }, (error: any) => {
          observer.error({
            "status": error.status,
            "errors": error.error
          });
        });
    });

  }

}
