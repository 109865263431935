import {Component, Input} from '@angular/core';
import {LoginAware} from "./login.aware";
import {AuthenticationResponse, LoginService} from "./login.service";

@Component({
  selector: 'app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.css']
})
export class AppLoginComponent {
  busy:boolean = false;
  username: string;
  password: string;
  message: string;

  @Input() loginAware: LoginAware;

  constructor(protected loginService: LoginService) { }

  submit(): void {
    this.busy = true;
    this.loginService
      .login(this.username, this.password)
      .then((authenticationResponse: AuthenticationResponse) => {
        this.reset();

        this.loginAware.persistAuthorization(authenticationResponse.authorization);
        this.loginAware.persistLoginMeta(authenticationResponse.meta);
        this.loginAware.signalLogin(this.username);

        this.busy = false;
      }, () => {
        this.message = 'Please check your credentials and try again';
        this.busy = false;
      });
  }

  reset(): void {
    this.username = '';
    this.password = '';
  }

  isBusy(): boolean {
    return this.busy;
  }
}
