<div class="container-fluid top-pad">

  <app-error-load [errorReportingComponent]="this"></app-error-load>

  <app-filter-page-list [entityService]="getEntityService()"
                        [entityNameValue]="entityName()"
                        [namedEntityList]="this"
                        [allowDescriptiveTextSort]="true">

    <ng-template #templateCreate>
      <button type="button" class="btn btn-outline-success mr-1" (click)="clickCreate()">Create {{ this.entityNameCapitalized() }}</button>
      <button type="button" class="btn btn-outline-info" (click)="clickViewProject()">View Project</button>
    </ng-template>

    <ng-template #templateTitle let-element="element">
      {{ element.type }}
<!--      {{ element | json }}-->
    </ng-template>

    <ng-template #templateButton let-element="element">
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickEdit(element)">Edit</button>
    </ng-template>

  </app-filter-page-list>

</div>
