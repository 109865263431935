<style>
  /* Prevent the transition/fade on nav items */
  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
</style>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="/">Tillera</a>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">


    <div class="cnav">
      <ul class="navbar-nav my-2 my-lg-0 mx-lg-5 justify-content-end">
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">Home</a>-->
<!--        </li>-->

        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">Home</a>
        </li>

<!-- TODO - 2021-10-24 - This is problematic because it doesn't include the context of a client -->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" [routerLink]="['/project/list']" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">Projects</a>-->
<!--        </li>-->

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle"
             href="#" id="navbarDropdownMenuLink"
             data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">Manage</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a href="" class="dropdown-item" [routerLink]="['/client/list']">Clients</a>
            <div class="dropdown-divider"></div>
            <a href="" class="dropdown-item" [routerLink]="['/organizational-contact/list']">Organization Contacts</a>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link" href="" (click)="this.clickLogout()">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
