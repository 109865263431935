<div class="container-fluid top-pad">

  <app-error-load [errorReportingComponent]="this"></app-error-load>

  <app-filter-page-list [entityService]="getEntityService()"
                        [entityNameValue]="entityName()"
                        [namedEntityList]="this"
                        [archivable]="true"
                        [allowDescriptiveTextSort]="true"
                        [sortDirection]="this.getDefaultSortDirection()"
                        [sortField]="this.getDefaultSortField()">

    <ng-template #templateCreate>
      <button type="button" class="btn btn-outline-success mr-1" (click)="clickCreate()">Create {{ this.entityNameCapitalized() }}</button>
      <button type="button" class="btn btn-outline-info" (click)="clickViewClient()">View Client</button>
    </ng-template>

    <ng-template #templateTitle let-element="element">
      {{ element.name }}
    </ng-template>

    <ng-template #templateButton let-element="element" let-hideable="hideable">
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickEdit(element)" [disabled]="element.archived">Edit</button>
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickPermits(element)" [disabled]="element.archived">Permits</button>
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickInspections(element)">Inspections</button>
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickArchiveToggle(element, hideable)">
        <span *ngIf="!element.archived">Archive</span>
        <span *ngIf="element.archived">Unarchive</span>
      </button>
    </ng-template>

  </app-filter-page-list>

</div>
