import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ClientProjectPermitService} from "../app-project-permit/client-project-permit-service";
import {AppListViewBase} from "../app-list/app-list-view-base";
import {ErrorReportingComponent} from "../app-error-load/app-error-load.component";

@Component({
  selector: 'app-project-permit-list',
  templateUrl: './app-project-permit-list.component.html',
  styleUrls: ['./app-project-permit-list.component.css']
})
export class AppProjectPermitListComponent extends AppListViewBase implements ErrorReportingComponent {

  constructor(protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router,
              protected clientProjectPermitService: ClientProjectPermitService) {

    super(activatedRoute, location, router);
  }

  clickCreateQueryParams(): Record<string, string> {
    return {"projectId": this.pageCriteria.getValue()?.projectId};
  }

  public entityName(): string {
    return "permit";
  }

  public getEntityService(): ClientProjectPermitService {
    return this.clientProjectPermitService;
  }
}
//              <span>{{ this.entityNameCapitalized() }} Management</span>
