import {Injectable} from "@angular/core";
import {EntityPage, EntityService} from "../base/base-service";
import {FileGroup} from "../domain/file-group";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";
import {Observable, Observer} from "rxjs";

@Injectable()
export class FileGroupService extends EntityService<FileGroup> {
  constructor(http: HttpClient, cookieMgr: CookieMgr) {
    super(http, cookieMgr);
  }

  protected getUrl(): string {
    return "/v2/fileGroup"
  }

  getPostBody(v: FileGroup): any {
    return JSON.stringify(v);
  }

  handleDownload(fileGroupId: number, fileGroupFileId: number) {
    const authorization = this.cookieMgr.getAuthorizationCookie();

    if (!authorization) {
      throw new Error("No Authorization available");
    }

    // TODO - Update this
    const url = `/v2/download/${fileGroupId}/${fileGroupFileId}`

    new Observable<any>((observer: Observer<any>) => {

      this.http
        .get<HttpResponse<Blob>>(url, {
          headers: new HttpHeaders().set('Authorization', authorization ? authorization : ''),
          "observe": 'response',
          "responseType": "blob" as "json"
        }).subscribe((response) => {
          const headerContentDisposition = response.headers?.get('content-disposition');
          const filename = headerContentDisposition.match(/.*\"(.*)\"/)[1];

          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {"type": `${dataType}`}));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }, (error) => {
          observer.error({
            "status": error.status,
            "errors": error.error
          })
        });
    }).subscribe((z) => {
    })
  }

  getByEntityIdAndType(entityId: number|string, entityType: number|string): Observable<FileGroup> {
    const authorization = this.cookieMgr.getAuthorizationCookie();

    if (!authorization) {
      throw new Error("No Authorization available");
    }

    const options = {
      headers: new HttpHeaders().set('Authorization', authorization ? authorization : ''),
    };

    let httpParams = new HttpParams();
    httpParams = httpParams.set('entityId', `${entityId}`).set('entityType', `${entityType}`);
    options["params"] = httpParams;

    return new Observable<FileGroup>((observer: Observer<FileGroup>) => {

      this.http
        .get<EntityPage<FileGroup>>(this.getUrl(), options)
        .subscribe((o: any) => {
          observer.next(this.instanceOfEntity(o.data[0]));
        }, (error) => {
          observer.error({
            "status": error.status,
            "errors": error.error
          });
        });

    });
  }

  instanceOfEntity(params: any): FileGroup {
    return new FileGroup(params);
  }
}
