import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";
import {EntityService} from "../base/base-service";
import {ClientProjectPermit} from "../domain/client-project-permit";
import {PageService} from "../app-list/app-list-base.component";


@Injectable()
export class ClientProjectPermitService extends EntityService<ClientProjectPermit> implements PageService<ClientProjectPermit>{

  constructor(http: HttpClient, cookieMgr: CookieMgr) {
    super(http, cookieMgr);
  }

  getUrl(): string {
    return "/v2/permit";
  }

  instanceOfEntity(params: any): ClientProjectPermit {
    return new ClientProjectPermit(params);
  }

  getPostBody(v: ClientProjectPermit): any {
    return v;
  }

}
