import {Component, Input, OnInit} from '@angular/core';
import {FlashMessage} from "../base/base-add-edit.component";

@Component({
  selector: 'app-flash-msg',
  templateUrl: './app-flash-msg.component.html',
  styleUrls: ['./app-flash-msg.component.css']
})
export class AppFlashMsgComponent implements OnInit {
  @Input()
  flashMessage: FlashMessage;

  constructor() { }

  ngOnInit(): void {
  }

}
