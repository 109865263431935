import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {InspectionService} from "../app-inspection/inspection-service";
import {ProjectInspection} from "../domain/client-project-inspection";
import {ClientProjectPermitService} from "../app-project-permit/client-project-permit-service";
import {BehaviorSubject} from "rxjs";
import {PageCriteria} from "../base/page-criteria";
import {AppListViewBase} from "../app-list/app-list-view-base";
import {SortDirection, SortField} from "../app-list-filter/app-list-filter.component";


@Component({
  selector: 'app-inspection-list',
  templateUrl: './app-inspection-list.component.html',
  styleUrls: ['./app-inspection-list.component.css']
})
export class AppInspectionListComponent extends AppListViewBase {
  refreshSignal: BehaviorSubject<PageCriteria> = new BehaviorSubject(null);
  permitsExist: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router,
              protected inspectionService: InspectionService,
              protected permitService: ClientProjectPermitService) {

    super(activatedRoute, location, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pageCriteria.subscribe((pageCriteria) => {
      if (null === pageCriteria) {
        return
      }

      this.permitService
        .getPage(1, 1, PageCriteria.fromParams({projectId: pageCriteria.projectId}))
        .subscribe((pl) => {
          this.permitsExist.next(pl.data.length !== 0);
        });
    });
  }

  public entityName(): string {
    return "inspection";
  }

  public getEntityService(): InspectionService {
    return this.inspectionService;
  }

  dialogPermitRequiredVisible(): boolean {
    if (null === this.permitsExist.getValue()) {
      return false;
    } else {
      return !this.permitsExist.getValue();
    }
  }

  buttonNewInspectionDisabled(): boolean {
    if (null === this.permitsExist.getValue()) {
      return true;
    } else {
      return !this.permitsExist.getValue();
    }
  }

  clickCreateQueryParams(): Record<string, string> {
    return {"projectId": this.pageCriteria.getValue()?.projectId};
  }

  clickReport(projectInspection: ProjectInspection) {
    this.router
      .navigateByUrl(`/inspection/report/subject/${projectInspection.id}`)
      .then((z) => {
      });
  }

  clickClone(projectInspection: ProjectInspection) {
    this
      .inspectionService
      .clone(projectInspection.id)
      .subscribe((z) => {
        const projectId = this.pageCriteria.getValue()?.projectId;
        this.refreshSignal.next(PageCriteria.fromParams({projectId: projectId}));
      })
  }

  public getDefaultSortField(): SortField {
    return SortField.created;
  }

  public getDefaultSortDirection(): SortDirection {
    return SortDirection.descending;
  }
}
