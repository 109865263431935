import {BaseEntity} from "../base/base-entity";

export class Organization extends BaseEntity {
  name: string;

  constructor(params: any = {}) {
    super(params);

    this.name = params.name;
  }

}
