import {ChangeDetectorRef, Component} from '@angular/core';
import {Client} from "../domain/client";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {BaseAddEditComponent} from "../base/base-add-edit.component";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientService} from "./client.service";

@Component({
  selector: 'app-client',
  templateUrl: './app-client.component.html',
  styleUrls: ['./app-client.component.css']
})
export class AppClientComponent extends BaseAddEditComponent<Client> {
  formElements = {
    "name": this.az09_(2, 250)
  };

  constructor(location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              entityService: ClientService,
              fb: FormBuilder,
              protected changeDetectorRef: ChangeDetectorRef) {
    super(location, router, activatedRoute, entityService, fb);
  }

  /* TODO - changeDetectorRef needs to move to super, but that will cause all children to break */
  ngOnInit() {
    super.ngOnInit();
    this.changeDetectorRef.detectChanges();
  }

  entityName(): string {
    return "client";
  }

  getFormElements(): Record<string, any> {
    return this.formElements;
  }

  updateFormGroup(v: Client): any {
    const newEntityFormGroupValue = Object.assign(
      this.fieldsBase(v), {
        name: this.fieldDefault(v.name),
      });

    this.entityFormGroup.setValue(newEntityFormGroupValue);
  }

}
