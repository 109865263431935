import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";


export class AuthenticationResponse {
  authorization: string;
  meta:{
    username: string,
    organization: {
      id: string
      name: string
    }
  }
}

class AuthenticationRequest {
  username: string;
  password: string;

  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }

}

@Injectable()
export class LoginService {

  constructor(protected httpClient: HttpClient, protected cookieMgr: CookieMgr) {}

  public login(username: string, password: string): Promise<AuthenticationResponse> {
    const request = new AuthenticationRequest(username, password);

    return new Promise<AuthenticationResponse>((resolve, reject) => {
      this.httpClient
        .post('/v2/authenticate', request)
        .subscribe((response: AuthenticationResponse) => {
          resolve(response);
        }, () => {
          reject();
        });
    });
  }

  public logout(): Promise<any> {
    const authorizationHeader = this.cookieMgr.getCookie('Authorization');
    if(authorizationHeader) {
      this.cookieMgr.deleteCookie('Authorization');
      const options = {
        headers: new HttpHeaders().set('Authorization', authorizationHeader)
      };

      return new Promise<any>((resolve, reject) => {
        this.httpClient.delete(`/authenticate`, options)
          .subscribe((response: any) => {
            resolve(response);
          }, () => {
            reject();
          });
      });
    }else {
      return new Promise<any>((resolve, reject) => {
        resolve({});
      });
    }
  }
}
