import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseAddEditComponent} from "../base/base-add-edit.component";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl} from "@angular/forms";
import {ClientProjectPermitService} from "./client-project-permit-service";
import {ClientProjectPermit} from "../domain/client-project-permit";

@Component({
  selector: 'app-project-permit',
  templateUrl: './app-project-permit.component.html',
  styleUrls: ['./app-project-permit.component.css']
})
export class AppProjectPermitComponent extends BaseAddEditComponent<ClientProjectPermit> {
  formElements = {
    "projectId": this.az09_(2, 250),
    "type": this.az09_(2, 250),
    "startValid": this.htmlDate(),
    "expire": this.htmlDate(),
    "authorizationNo": this.az09_(2, 250),
  };

  constructor(location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              entityService: ClientProjectPermitService,
              fb: FormBuilder,
              protected changeDetectorRef: ChangeDetectorRef) {
    super(location, router, activatedRoute, entityService, fb);
  }

  /* TODO - changeDetectorRef needs to move to super, but that will cause all children to break */
  ngOnInit() {
    super.ngOnInit();
    this.changeDetectorRef.detectChanges();
  }

  entityName(): string {
    return "permit";
  }

  getFormElements(): Record<string, any> {
    return this.formElements;
  }

  protected handleParametersExtend(id: string, queryParams: Record<string, string>) {
    this.entityFormGroup.patchValue({"projectId": queryParams["projectId"]});
  }

  updateFormGroup(v: ClientProjectPermit): any {
    const newEntityFormGroupValue = Object.assign(
      this.fieldsBase(v), {
        projectId: this.fieldDefault(v.clientId),
        type: this.fieldDefault(v.type),
        startValid: this.fieldDefault(v.startValid),
        expire: this.fieldDefault(v.expire),
        authorizationNo: this.fieldDefault(v.authorizationNo),
      });

    this.entityFormGroup.setValue(newEntityFormGroupValue);
  }

}
