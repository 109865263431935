<div>

  <div *ngIf="loginAware.loggedIn">
    <app-nav [loginAware]="loginAware"></app-nav>
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="!loginAware.loggedIn">
    <app-login [loginAware]="loginAware"></app-login>
  </div>
</div>
