import {BaseEntity} from "../base/base-entity";
import {Client} from "../domain/client";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {Component, OnInit} from "@angular/core";
import {PageCriteria} from "../base/page-criteria";
import {BehaviorSubject} from "rxjs";
import {SortDirection, SortField} from "../app-list-filter/app-list-filter.component";

@Component({
  template: ''
})

export abstract class AppListViewBase implements OnInit {
  visibleError: boolean;
  pageCriteria: BehaviorSubject<PageCriteria> = new BehaviorSubject<PageCriteria>(null);


  constructor(protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router) {
  }

  abstract entityName(): string;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.pageCriteria.next(PageCriteria.fromParams(params, {'sortField': this.getDefaultSortField(), 'sortDirection': this.getDefaultSortDirection()}))
    });
  }

  // TODO - Refactor to consolidate
  public entityNameCapitalized(): string {
    return this
      .entityName()
      .split('-')
      .map((e) => `${e.charAt(0).toUpperCase()}${e.slice(1)}` )
      .join(' ');
  }

  clickEdit(entity: BaseEntity): void {
    this.router
      .navigateByUrl(`/${this.entityName()}/${entity.id}`)
      .then((z) => {
      });
  }

  clickProjects(client: Client): void {
    this.router
      .navigateByUrl(`/project/list?clientId=${client.id}`)
      .then((z) => {
      });
  }

  clickContacts(client: Client): void {
    this.router
      .navigateByUrl(`/contact/list?clientId=${client.id}`)
      .then((z) => {
      });
  }

  clickViewClient(): void {
    let url = `/client/${this.pageCriteria.getValue()?.clientId}`;

    this.router
      .navigateByUrl(url)
      .then((z) => {
      });
  }

  clickViewProject(): void {
    let url = `/project/${this.pageCriteria.getValue()?.projectId}`;

    this.router
      .navigateByUrl(url)
      .then((z) => {
      });
  }

  buildQueryParameter(key: string, value: string, amp: boolean = true): string {
    const keyValue = value ? `${key}=${value}` : "";
    return `${keyValue && amp ? "&" : ""}${keyValue}`;
  }

  clickCreateQueryParams(): Record<string, string> {
    return {};
  }

  clickCreate(): void {
    const queryParams = this.clickCreateQueryParams();

    let queryStringParameters = "";

    if (queryParams && Object.keys(queryParams).length > 0) {
      for (let k in queryParams) {
        queryStringParameters += this.buildQueryParameter(k, queryParams[k]);
      }
    }
    console.log(`this.entityName()=${this.entityName()}`);
    let url = `/${this.entityName()}`;

    if (queryStringParameters.length > 0) {
      url = `${url}?${queryStringParameters}`;
    }

    this.router
      .navigateByUrl(url)
      .then((z) => {
      });
  }

  getDefaultSortField(): SortField {
    return SortField.created;
  }

  getDefaultSortDirection(): SortDirection {
    return SortDirection.descending;
  }
}
