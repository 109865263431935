<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">
    <div *ngIf="this.isArchived()" class="cub">
      <span class="cub-value">This record is archived</span>
    </div>
  </div>
</div>

<!--<ng-template #elseNotUpdated>-->
<!-- hack required for Sarafi: 'Unable to convert "Invalid Date" into a date' for pipe 'DatePipe' -->
  <div class="cub">
    <span class="cub-value">Created {{ this.hackDate(element.created.date) | date}}</span>
    <span class=""> by </span>
    <span class="cub-value">{{ element.created.user.username }}</span>
  </div>

<!--</ng-template>-->
<div *ngIf="element.updated;" class="cub">
  <span class="cub-value">Updated {{ this.hackDate(element.updated.date) | date}}</span>
  <span class=""> by </span>
  <span class="cub-value">{{ element.updated?.user?.username }}</span>
</div>

