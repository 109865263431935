import {EventEmitter} from "@angular/core";
import {CookieMgr} from "../util/cookie-mgr";

export class LoginAware {
  cookieMgr: CookieMgr =  new CookieMgr();
  loggedIn: boolean = false;
  login: EventEmitter<any> = new EventEmitter();
  logout: EventEmitter<any> = new EventEmitter();

  persistAuthorization(authorization: string): void {
    this.cookieMgr.setAuthorizationCookie(authorization);
  }

  persistLoginMeta(loginMeta: any): void {
    this.cookieMgr.setCookieLoginMeta(JSON.stringify(loginMeta));
  }

  signalLogin(account: string): void {
    this.loggedIn = true;
    this.login.emit();
  }

  signalLogout(): void {
    this.loggedIn = false;
    this.cookieMgr.deleteCookie(CookieMgr.COOKIE_TSV2_AUTHORIZATION);
    this.logout.emit({});
  }
}
