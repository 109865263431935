import {Component, Input} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {EntityPage, EntityService} from "../base/base-service";
import {BaseEntity} from "../base/base-entity";
import {PageCriteria} from "../base/page-criteria";
import {Location} from "@angular/common";

export interface PageService<ENTITY> {
  getPage(pageSize: number, page: number, entityPageCriteria: PageCriteria): Observable<EntityPage<ENTITY>>;
}

export interface NamedEntityList {
  entityName(): string;
  entityNameCapitalized(): string;
}

@Component({
  template: ''
})
export abstract class AppListBase {

  @Input()
  entityPageSubject = new BehaviorSubject<EntityPage<BaseEntity>>(null);

  @Input()
  entityService: EntityService<BaseEntity>;

  @Input()
  namedEntityList: NamedEntityList;

  @Input()
  pageCriteria: PageCriteria;

  @Input()
  refreshSignal: BehaviorSubject<PageCriteria> = new BehaviorSubject(null);

  constructor(protected location: Location) {
  }

  protected buildQueryParameter(key: string, value: string, amp: boolean = true): string {
    const keyValue = value ? `${key}=${value}` : "";
    return `${keyValue && amp ? "&" : ""}${keyValue}`;
  }

  protected clickNavigate(callable: (pageCriteria: PageCriteria) => PageCriteria): void {
    const pageCriteria = this.entityPageSubject.getValue()?.criteria;
    // TODO - Sort this out: this.pageCriteria is null at times
    pageCriteria.projectId = this.pageCriteria?.projectId;
    // TODO - Refactor/resolve this - it is a hack ; also pageCriteria.projectId type should match the project.id type
    if(pageCriteria.projectId === undefined && this.entityPageSubject.getValue().page?.context?.project?.id !== undefined) {
      pageCriteria.projectId = `${this.entityPageSubject.getValue().page?.context?.project?.id}`;
    }
    pageCriteria.page = this.entityPageSubject.getValue()?.page.current;
    pageCriteria.pageSize = this.entityPageSubject.getValue()?.page.size;
    pageCriteria.sortField = this.entityPageSubject.getValue()?.sort.field;
    pageCriteria.sortDirection = this.entityPageSubject.getValue()?.sort.direction;

    const pageCriteriaNext = callable(pageCriteria);
    this.fetchPage(pageCriteriaNext);
  }

  protected fetchPage(pageCriteria: PageCriteria): void {
    this.entityService
      .getPage(pageCriteria.pageSize, pageCriteria.page, pageCriteria)
      .subscribe((o) => {
        this.entityPageSubject.next(o);

        const pathPage = `page=${this.entityPageSubject.getValue()?.page.current}`;
        const pathPageSize = `&pageSize=${this.entityPageSubject.getValue()?.page.size}`;

        const pProjectId = this.buildQueryParameter("projectId", pageCriteria.projectId);
        const pClientId = this.buildQueryParameter("clientId", pageCriteria.clientId);
        const pName = this.buildQueryParameter("desc", pageCriteria.desc);
        const pCreateLow = this.buildQueryParameter("createLow", pageCriteria.createLow);
        const pCreateHigh = this.buildQueryParameter("createHigh", pageCriteria.createHigh);
        const pUpdateLow = this.buildQueryParameter("updateLow", pageCriteria.updateLow);
        const pUpdateHigh = this.buildQueryParameter("updateHigh", pageCriteria.updateHigh);
        const pArchived = this.buildQueryParameter("archived", `${pageCriteria.archived}`);

        const pSortField = this.buildQueryParameter("sortField", `${pageCriteria.sortField}`);
        const pSortDirection = this.buildQueryParameter("sortDirection", `${pageCriteria.sortDirection}`);

        const pathCriteria = `${pName}${pCreateLow}${pCreateHigh}${pUpdateLow}${pUpdateHigh}${pClientId}${pProjectId}${pArchived}${pSortField}${pSortDirection}`;
        const path = `/${this.namedEntityList.entityName()}/list?${pathPage}${pathPageSize}${pathCriteria}`;

        this.location.replaceState(path);

        // TODO - M: Need to refactor 'visibleError' to behaviorSubject
        // this.visibleError = false;

        window.scrollTo(0,0);
      }, (error) => {
        // TODO - M: Need to refactor 'visibleError' to behaviorSubject
        // this.visibleError = true;
      });
  }
}
