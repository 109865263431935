import {BaseEntity} from "./base-entity";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {Component, OnInit} from "@angular/core";
import {BehaviorSubject, Subject, zip} from "rxjs";
import {EntityService} from "./base-service";
import {AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators} from "@angular/forms";

export class FlashMessage {
  messageHead = "default message";
  messageSub = "default message";
  isError = false;
  isVisible = false;

  constructor(messageHead: string, messageSub: string, isError: boolean) {
    this.messageHead = messageHead;
    this.messageSub = messageSub;
    this.isError = isError;
    this.isVisible = true;

    setTimeout(() => {
      if(!this.isError) {
        this.isVisible = false;
      }
    }, 10000);
  }
}

export function optionalValidator(validators?: (ValidatorFn | null | undefined)[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return control.value ? Validators.compose(validators)(control) : null;
  };
}

export interface BackCancelSave {
  clickCancel(): void;
  clickSave(): void;
  labelBackCancel(): string;
}

export enum FormEvent {
  saved = 0,
  saveFailed = 1,
}

@Component({
  template: ''
})
export abstract class BaseAddEditComponent<V extends BaseEntity> implements OnInit, BackCancelSave {
  targetSubject: BehaviorSubject<V> = new BehaviorSubject(null);

  // BaseEntity fields
  entityFormGroup = this.fb.group({
    created: [],
    updated: [],
    id: [],
  });

  // TODO - this is based on an observable and is only being read in the app-inspection.component ; not reading safely
  subjectEntityId: number;

  // TODO - would be good to merge with `targetSubject`
  // Set when the component loads with ID
  subjectInstance: V;

  // This will be set to true if the form fails on submission
  // when it is true, the invalid fields will be exposed
  saveFailedDueToValidation = false;

  constructor(protected location: Location,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected entityService: EntityService<V>,
              protected fb: FormBuilder) {}

  ngOnInit(): void {
    // This causes child class components to add component-specific fields to the entityFormGroup
    this.resetCommon();
    this.extendFormGroup();
    this.handleParameters();
  }

  protected resetCommon() {
    this.flashMessage = null;
    this.entityFormGroup.reset();
  }

  flashMessage: FlashMessage;

  /**
   * Override this to have a hook into adding values from query string parameters to the form group.
   * @param queryParams
   * @protected
   */
  protected handleParametersExtend(id: string, queryParams: Record<string, string>) {}

  protected queryParamsSubject: BehaviorSubject<Record<string, string>> = new BehaviorSubject<Record<string, string>>(undefined);

  protected handleParameters() {
    const observable = zip(this.activatedRoute.queryParams, this.activatedRoute.params);

    observable.subscribe(([queryParams, id]) => {
      this.queryParamsSubject.next(queryParams);
      this.handleParametersExtend(id?.id, queryParams);

      if(id?.id) {
        this.subjectEntityId = id.id;
        this.entityService.getById(id.id).subscribe((o) => {
          // TODO - This 'flashMessage' should be a behaviorSubject
          this.flashMessage = null;
          this.targetSubject.next(o);
          this.updateFormGroup(o);
        }, (error) => {
          this.flashMessage = new FlashMessage("Could not retrieve record", error.errors[0].message, true);
        });
      }
    });
  }

  fieldsBase(v: BaseEntity): any {
    return {
      id: this.fieldDefault(v.id),
      created: this.fieldDefault(v.created),
      updated: this.fieldDefault(v.updated),
    };
  }

  fieldDefault(fieldValue: any): any {
    return fieldValue ? fieldValue : '';
  }

  clickCancel(): void {
    this.location.back();
  }

  labelBackCancel(): string {
    if(!this.entityFormGroup.pristine) {
      return "Cancel";
    }else {
      return "Back";
    }
  }

  formEventSubject = new Subject<FormEvent>();

  clickSave(): void {
    this.saveFailedDueToValidation = false;

    for(let controlName in this.entityFormGroup.controls) {
      const control = this.entityFormGroup.get(controlName);

      if(!control.valid) {
        this.saveFailedDueToValidation = true;
      }
    }

    if(this.saveFailedDueToValidation) {
      return;
    }

    this.entityService
      .persist(this.entityFormGroup.getRawValue())
      .subscribe((next) => {
        const nextMarshalled = this.entityService.instanceOfEntity(next);
        const targetSubjectCurrent = this.targetSubject.getValue();

        if(targetSubjectCurrent) {
          targetSubjectCurrent.id = nextMarshalled.id;
          targetSubjectCurrent.created = nextMarshalled.created;
          targetSubjectCurrent.updated = nextMarshalled.updated;
        }else {
          this.targetSubject.next(nextMarshalled);
        }

        const path = `/${this.entityName()}/${next.id}`;

        this.location.replaceState(path);

        // Update the newly created entity with response from server so next save is an _update_ and not a _create_:
        this.entityFormGroup.patchValue({
          "id": next.id,
          "created": next.created,
          "updated": next.updated,
        });

        this.entityFormGroup.markAsPristine();
        this.flashMessage = new FlashMessage("Save completed.", "", false);
        this.formEventSubject.next(FormEvent.saved);
      }, (error) => {
        console.log("Encountered error");
        console.dir(error);
        this.flashMessage = new FlashMessage("Failed to save data.", "", true);
        this.formEventSubject.error(FormEvent.saveFailed);
      });
  }

  /**
   * Used to determine if the validation has failed for a form element
   * - Will only mark invalid -> red if the form has been submitted to avoid keyup/bounce
   *
   * @param formElementName
   */
  isValid(formElementName): boolean {
    const formElement = this.entityFormGroup.get(formElementName);

    // console.log(`this.saveFailedDueToValidation: ${this.saveFailedDueToValidation}`)
    // console.log(`formElementName: ${formElementName}, status: ${formElement.status}, dirty: ${formElement.dirty}, dirty && valid: ${formElement.dirty && formElement.valid}`)

    if(this.saveFailedDueToValidation) {
      return formElement.valid;
    }

    if(formElement.pristine) {
      return true;
    }else if(this.saveFailedDueToValidation) {
      return formElement.valid;
    }else {
      return true;
    }
  }

  /**
   * Child components
   */
  // abstract getFormElements(): Record<string, any>;
  getFormElements(): Record<string, any> {
    return null;
  };

  protected noop(): Record<string, any> {
    return {
      "error": `This value contains an error`,
      "validators": []
    }
  }

  protected az(minLength: number, maxLength: number): Record<string, any> {
    return {
      "error": `This value may be between ${minLength} to ${maxLength} characters and can include only letters`,
      "validators": [
        Validators.minLength(minLength),
        Validators.maxLength(maxLength),
        Validators.pattern("^[ a-zA-Z]+$")
      ]
    }
  }

  protected az09_(minLength: number, maxLength: number): Record<string, any> {
    return {
      "error": `Value may be between ${minLength} to ${maxLength} characters and can include letters, numbers, &, -, _, ., /, \\, '`,
      "validators": [
        Validators.minLength(minLength),
        Validators.maxLength(maxLength),
        Validators.pattern("^[\\\\/&\\sa-zA-Z0-9\\-_'.]+$")
      ]
    }
  }

  protected selectOne(): Record<string, any> {
    return {
      "error": "Select one available option",
    }
  }

  protected simple(minLength: number, maxLength: number): Record<string, any> {
    return {
      "error": `Value may be between ${minLength} to ${maxLength} characters`,
      "validators": [
        Validators.minLength(minLength),
        Validators.maxLength(maxLength),
      ]
    }
  }

  protected ynbool(): Record<string, any> {
    return {
      "error": `Value may only indicate true or false, yes or no`,
      "validators": [
        Validators.minLength(4),
        Validators.maxLength(5),
        Validators.pattern("^(true|false)$")
      ]
    }
  }

  protected link(): Record<string, any> {
    return {
      "error": `Value may only be a valid URL with http(s) protocol`,
      "validators": [
        // http://a.co
        Validators.minLength(11),
        Validators.pattern("^(http|https):\\/\\/.*?$")
      ]
    }
  }

  protected phone(): Record<string, any> {
    // nnn nnn nnnn 10 min digit/char
    // nnn-nnn-nnnn 12 chars
    // +1 nnn-nnn-nnnn 15 chars, w space and +

    return {
      "error": `Value may be between 10 to 15 characters and can include numbers, dashes, space, and plus symbol ie +1 555-555-1000`,
      "validators": [
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.pattern("^[ 0-9\-\+]+$")
      ]
    }
  }

  protected email(): Record<string, any> {
    return {
      "error": `Value must be a valid email address`,
      "validators": [
        Validators.email
      ]
    }
  }

  protected htmlDate(): Record<string, any> {
    return {
      "error": "Value must be a valid date in the form MM-DD-YYYY",
      "validators": [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$")
      ]
    }
  }

  protected zipcode(required:boolean = false): Record<string, any> {
    return {
      "error": `Value may be between 5 to 9 characters and can include numbers and a dash`,
      "required": required,
      "validators": [
        Validators.minLength(5),
        Validators.maxLength(10),
        Validators.pattern("^[0-9]{0,5}[\-]{0,1}[0-9]{0,4}$")
      ]
    }
  }

  protected decimal09(required:boolean = false): Record<string, any> {
    let validators = [Validators.pattern("^[-]{0,1}[0-9]+\\.?[0-9]{0,9}$")];
    validators = required ? validators : [optionalValidator(validators)]

    return {
      "error": `Value may include +/- symbols, numbers and a decimal point`,
      "required": required,
      "validators": validators
    }
  }

  protected lonlat(required:boolean = false): Record<string, any> {
    let validators = [Validators.pattern("^([\\-+]?[0-9]{1,2}[°]?\\s?[0-9]{1,2}'[0-9]{1,2}\\.[0-9]{1,2}\"[NESW])|([0-9]{1,2}\\.[0-9]{1,5})$")];
    validators = required ? validators : [optionalValidator(validators)]

    return {
      "error": `Value must be in DMS format D°M'S.S"NESW, where ° is optional`,
      "required": required,
      "validators": validators
    }
  }

  extendFormGroup(): void {
    const formElements = this.getFormElements();

    for(let key in formElements) {
      // console.log(`key: ${key}`);
      // console.dir(formElements[key].validators);
      this.entityFormGroup.addControl(key, new FormControl(null, formElements[key].validators));
    }
  }

  abstract updateFormGroup(v: V): any;

  abstract entityName(): string;

  // TODO - Refactor to consolidate
  public entityNameCapitalized(): string {
    return this
      .entityName()
      .split('-')
      .map((e) => `${e.charAt(0).toUpperCase()}${e.slice(1)}` )
      .join(' ');
  }

  /**
   * Hook to allow child component to pull information off the response object
   * that might not fit the form.
   */
  handleEntityLoad(): void {} // TODO - targetSubject makes this not needed?
}
