<div class="text-right" style="padding-top:0.10em;">
  <span>&nbsp;</span>
  <span *ngIf="flashMessage?.isVisible">
    <span *ngIf="!flashMessage?.isError">
      <span class="oi oi-circle-check" style="padding-right:0.25em;color:green;"></span>
    </span>
    <span *ngIf="flashMessage?.isError">
      <span class="oi oi-warning" style="padding-right:0.25em;color:red;"></span>
    </span>
    <span>{{ this.flashMessage?.messageHead }}</span>
  </span>
</div>
