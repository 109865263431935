<div class="container-fluid top-pad" >

  <div class="row top-pad">

    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <div>
            <h5>Inspection Detail</h5>
          </div>

          <div class="card-subtitle mb-2">
            <span class="project-meta">Permitted Project Name</span>
            <span>&nbsp;</span>
            <a [routerLink]="['/project', this.projectId()]">
              <span class="project-meta project-meta-value">{{ this.projectName() }}</span>
            </a>
          </div>

          <div class="card-subtitle mb-2">
            <span class="project-meta">Permit Authorization Number</span>
            <span>&nbsp;</span>

            <a [routerLink]="['/permit', this.permit()?.id]" routerLinkActive="active">
              <span class="project-meta project-meta-value">{{ this.permit()?.authorizationNo }}</span>
            </a>

          </div>

          <div class="card-subtitle mb-2">
            <span class="project-meta">Permit Start Date</span>
            <span>&nbsp;</span>
            <span class="project-meta project-meta-value">{{ this.permit()?.startValid }}</span>
          </div>

          <div class="card-subtitle mb-2">
            <span class="project-meta">Permit Expiration Date</span>
            <span>&nbsp;</span>
            <span class="project-meta project-meta-value">{{ this.permit()?.expire }}</span>
          </div>

        </div>
      </div>
    </div>
  </div>


<!--  <div class="row sub-pad" *ngIf="this.flashMessage">-->
<!--    <div class="col-lg-6 col-sm-12">-->
<!--      <div class="alert alert-danger" role="alert" *ngIf="this.flashMessage.isError">-->
<!--        <h4 class="alert-heading">{{ this.flashMessage.messageHead }}</h4>-->
<!--        <p>{{ this.flashMessage.messageSub }}</p>-->
<!--        <hr>-->
<!--        <div class="text-right">-->
<!--          <a class="btn btn-outline-info my-2 my-sm-0" data-dismiss="alert">Dismiss</a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!-- This block is the fileGroup-->
<!--  <div class="row sub-pad">-->
<!--    <div class="col-lg-6 col-sm-12">-->
<!--      <app-file-group [fileGroupSupportSubject]="this.fgSupport"></app-file-group>-->
<!--    </div>-->
<!--  </div>-->

  <div class="row sub-pad">
    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
<!--          <form [formGroup]="entityFormGroup" (keydown.enter)="$event.preventDefault()" (keyup.enter)="$event.stopPropagation()">-->
          <form [formGroup]="entityFormGroup">
            <input type="hidden" value="" id="id" name="id" />

            <div class="form-group">
              <label for="erosionControlNotes">Notes</label>
              <textarea class="form-control"
                        id="erosionControlNotes"
                        name="erosionControlNotes"
                        formControlName="erosionControlNotes"
                        autocomplete="off"
                        style="height:20em;"
                        required
                        [ngClass]="{'invalid': !this.isValid('erosionControlNotes')}"></textarea>
            </div>

            <validator-msg [component]="this" formElementName="erosionControlNotes"></validator-msg>

            <div class="form-group">
              <label for="dateOf">Date of Inspection</label>
              <input type="date"
                     class="form-control"
                     id="dateOf"
                     name="dateOf"
                     placeholder="Date of Inspection"
                     autocomplete="off"
                     formControlName="dateOf"
                     required [ngClass]="{'invalid': !this.isValid('dateOf')}">
            </div>

            <validator-msg [component]="this" formElementName="dateOf"></validator-msg>

            <div class="form-group">
              <label for="currentPhase">Current Phase</label>
              <input type="text"
                     class="form-control"
                     id="currentPhase"
                     name="currentPhase"
                     placeholder="Current Phase"
                     autocomplete="off"
                     formControlName="currentPhase"
                     required [ngClass]="{'invalid': !this.isValid('currentPhase')}">
            </div>

            <validator-msg [component]="this" formElementName="currentPhase"></validator-msg>

            <div class="form-group">
              <label for="currentWeather">Current Weather</label>
              <input type="text"
                     class="form-control"
                     id="currentWeather"
                     name="currentWeather"
                     placeholder="Current Weather"
                     autocomplete="off"
                     formControlName="currentWeather"
                     required [ngClass]="{'invalid': !this.isValid('currentWeather')}">
            </div>

            <validator-msg [component]="this" formElementName="currentWeather"></validator-msg>

            <div class="form-group">
              <label for="question1">Are all slopes and disturbed area not actively being worked properly stabilized?</label>
              <select class="custom-select"
                      id="question1"
                      name="question1"
                      formControlName="question1"
                      required [ngClass]="{'invalid': !this.isValid('question1')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question1"></validator-msg>

            <div class="form-group">
              <label for="question2">Are natural resource areas (e.g. streams, wetlands, mature trees, etc.) protected with barriers or similar BMPs?</label>
              <select class="custom-select"
                      id="question2"
                      name="question2"
                      formControlName="question2"
                      required [ngClass]="{'invalid': !this.isValid('question2')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question2"></validator-msg>

            <div class="form-group">
              <label for="question3">Are perimeter controls and sediment barriers adequately installed (keyed into substrate) and maintained?</label>
              <select class="custom-select"
                      id="question3"
                      name="question3"
                      formControlName="question3"
                      required [ngClass]="{'invalid': !this.isValid('question3')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question3"></validator-msg>

            <div class="form-group">
              <label for="question4">Are discharge points and receiving waters free of sediment deposits?</label>
              <select class="form-control"
                      id="question4"
                      name="question4"
                      formControlName="question4"
                      required [ngClass]="{'invalid': !this.isValid('question4')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question4"></validator-msg>

            <div class="form-group">
              <label for="question5">Are storm drain inlets properly protected?</label>
              <select class="form-control"
                      id="question5"
                      name="question5"
                      formControlName="question5"
                      required [ngClass]="{'invalid': !this.isValid('question5')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question5"></validator-msg>

            <div class="form-group">
              <label for="question6">Are paved areas free of significant sediment tracking and debris?</label>
              <select class="form-control"
                      id="question6"
                      name="question6"
                      formControlName="question6"
                      required [ngClass]="{'invalid': !this.isValid('question6')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question6"></validator-msg>

            <div class="form-group">
              <label for="question7">Are site entrances & exits stabilized with rock or other appropriate means?</label>
              <select class="form-control"
                      id="question7"
                      name="question7"
                      formControlName="question7"
                      required [ngClass]="{'invalid': !this.isValid('question7')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question7"></validator-msg>

            <div class="form-group">
              <label for="question8">Are Structural BMPs such as diversion berms, weirs, spillways, standpipes, etc. in place and functioning?</label>
              <select class="form-control"
                      id="question8"
                      name="question8"
                      formControlName="question8"
                      required [ngClass]="{'invalid': !this.isValid('question8')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question8"></validator-msg>

            <div class="form-group">
              <label for="question9">For sites containing common drainage of 10 ACs+, is there a basin in place & functioning at engineered capacity levels?</label>
              <select class="form-control"
                      id="question9"
                      name="question9"
                      formControlName="question9"
                      required [ngClass]="{'invalid': !this.isValid('question9')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question9"></validator-msg>

            <div class="form-group">
              <label for="question10">Are the appropriate dust control measures being taken during dry or arid time frames?</label>
              <select class="form-control"
                      id="question10"
                      name="question10"
                      formControlName="question10"
                      required [ngClass]="{'invalid': !this.isValid('question10')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question10"></validator-msg>

            <div class="form-group">
              <label for="question11">Are non-storm water discharges (e.g. wash water, dewatering) properly controlled?</label>
              <select class="form-control"
                      id="question11"
                      name="question11"
                      formControlName="question11"
                      required [ngClass]="{'invalid': !this.isValid('question11')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question11"></validator-msg>

            <div class="form-group">
              <label for="question12">Are vehicle & equipment fueling & maintenance areas clean & free of spills, leaks, or any other deleterious materials?</label>
              <select class="form-control"
                      id="question12"
                      name="question12"
                      formControlName="question12"
                      required [ngClass]="{'invalid': !this.isValid('question12')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question12"></validator-msg>

            <div class="form-group">
              <label for="question13">Are washout facilities (e.g., paint, stucco, concrete) available, clearly marked and maintained?</label>
              <select class="form-control"
                      id="question13"
                      name="question13"
                      formControlName="question13"
                      required [ngClass]="{'invalid': !this.isValid('question13')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question13"></validator-msg>

            <div class="form-group">
              <label for="question14">Are materials that are potential storm water contaminants being stored properly?</label>
              <select class="form-control"
                      id="question14"
                      name="question14"
                      formControlName="question14"
                      required [ngClass]="{'invalid': !this.isValid('question14')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question14"></validator-msg>

            <div class="form-group">
              <label for="question15">Is trash/litter from work areas collected and placed in dumpsters?</label>
              <select class="form-control"
                      id="question15"
                      name="question15"
                      formControlName="question15"
                      required [ngClass]="{'invalid': !this.isValid('question15')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question15"></validator-msg>

            <div class="form-group">
              <label for="question16">Is the current NPDES General Permit 2 included in the SWPPP?</label>
              <select class="form-control"
                      id="question16"
                      name="question16"
                      formControlName="question16"
                      required [ngClass]="{'invalid': !this.isValid('question16')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question16"></validator-msg>

            <div class="form-group">
              <label for="question17">Are all applicable Contractor Certifications signed and included in the SWPPP?</label>
              <select class="form-control"
                      id="question17"
                      name="question17"
                      formControlName="question17"
                      required [ngClass]="{'invalid': !this.isValid('question17')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question17"></validator-msg>

            <div class="form-group">
              <label for="question18">Is the Construction Schedule being kept up to date noting all major activity</label>
              <select class="form-control"
                      id="question18"
                      name="question18"
                      formControlName="question18"
                      required [ngClass]="{'invalid': !this.isValid('question18')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question18"></validator-msg>

            <div class="form-group">
              <label for="question19">Is the SWPPP plan being kept current reflecting changes to controls and surrounding site features as they occur?</label>
              <select class="form-control"
                      id="question19"
                      name="question19"
                      formControlName="question19"
                      required [ngClass]="{'invalid': !this.isValid('question19')}">
                <option></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <validator-msg [component]="this" formElementName="question19"></validator-msg>

            <div class="form-group">
              <label for="urlMedia">Media content link (ie Google Drive share URL)</label>
              <input type="text"
                     class="form-control"
                     id="urlMedia"
                     name="urlMedia"
                     placeholder=""
                     autocomplete="off"
                     formControlName="urlMedia"
                     placeholder="https://">
            </div>

            <validator-msg [component]="this" formElementName="urlMedia"></validator-msg>

            <!-- Signed/Certified Start -->
            <div class="form-group">
              <div>
                <span>I certify under penalty of law that this document and all attachments were prepared under my direction or supervision in accordance with a system designed to assure that qualified personnel properly gathered and evaluated the information submitted. Inquiry of the person or persons who manage the system or those persons directly responsible for gathering the information, the information submitted is, to the best of my knowledge and belief, true, accurate and complete. I am aware that there are significant penalties for submitting false information, including the possibility of fine and imprisonment for knowing violations."</span>
              </div>

              <div *ngIf="this.isSigned(); else elseNotSigned">
                <div class="top-pad">
                  <span class="certify-statement">
                    {{ this.certifiedInfo() }}
                  </span>
                </div>
              </div>

              <ng-template #elseNotSigned>
                <div>
                  <div class="top-pad">
                    <label>Click or tap the button labeled, "Certify", below, to digitally sign and certify the above report</label>
                  </div>
                  <div class="text-right">
                    <button type="button"
                            class="btn btn-outline-info my-2 my-sm-0"
                            [disabled]="this.signed"
                            (click)="toggleSigned()">Certify</button>
                  </div>

                </div>
              </ng-template>
              <hr />
            </div>
            <!-- Signed/Certified End -->

            <div class="form-group text-right">
              <button type="button" class="btn btn-outline-info my-2 my-sm-0" (click)="this.toggleFlagged()">
                <span>{{ this.flagged() }}</span>
              </button>
            </div>
            <app-flash-msg [flashMessage]="this.flashMessage"></app-flash-msg>
            <app-bcs-button-group [backCancelSave]="this"></app-bcs-button-group>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
