<div class="container-fluid top-pad">

  <app-error-load [errorReportingComponent]="this"></app-error-load>

  <app-filter-page-list [entityService]="getEntityService()"
                        [entityNameValue]="entityName()"
                        [namedEntityList]="this"
                        [allowDescriptiveTextSort]="true"
                        [sortDirection]="this.getDefaultSortDirection()"
                        [sortField]="this.getDefaultSortField()">

    <ng-template #templateCreate>
      <button type="button" class="btn btn-outline-success mr-1" (click)="clickCreate()">Create {{ this.entityNameCapitalized() }}</button>
      <button type="button" class="btn btn-outline-info" (click)="clickViewClient()" [disabled]="isDisabled()">View Client</button>
    </ng-template>

    <ng-template #templateTitle let-element="element">
      {{ element.firstName }} {{ element.lastName }}
    </ng-template>

    <ng-template #templateButton let-element="element">
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickEdit(element)">Edit</button>
    </ng-template>

  </app-filter-page-list>

</div>
