import {Component} from '@angular/core';
import {ClientService} from "../app-client/client.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ErrorReportingComponent} from "../app-error-load/app-error-load.component";
import {AppListViewBase} from "../app-list/app-list-view-base";
import {SortDirection, SortField} from "../app-list-filter/app-list-filter.component";
import {ArchivableEntity} from "../domain/archivable-entity";
import {Hideable} from "../app-filter-page-list-card-container/app-filter-page-list-card-container.component";
import {ClientProject} from "../domain/client-project";


@Component({
  selector: 'app-client-list',
  templateUrl: './app-client-list.component.html',
  styleUrls: ['./app-client-list.component.css']
})
export class AppClientListComponent extends AppListViewBase implements ErrorReportingComponent {

  visibleError: boolean;

  constructor(protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router,
              protected clientService: ClientService) {

    super(activatedRoute, location, router);
  }

  public entityName(): string {
    return "client";
  }

  public getEntityService(): ClientService {
    return this.clientService;
  }

  public getDefaultSortField(): SortField {
    return SortField.descriptiveText;
  }

  public getDefaultSortDirection(): SortDirection {
    return SortDirection.ascending;
  }

  // TODO - Refactor to "archivable base"
  clickArchiveToggle(entity: ArchivableEntity, hideable: Hideable): void {
    entity.archived = !entity.archived;

    this
      .getEntityService()
      .persist(entity as ClientProject)
      .subscribe((obj) => {
        hideable.hide();
      });
  }
}
