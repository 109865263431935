<div class="row mt-3">

  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">

    <div class="row mb-1">

      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 offset-lg-0 offset-xl-0">
        <div class="card rounded-0 card-body">

          <div class="row mb-1">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 offset-lg-0 offset-xl-0">
              <app-list-filter [archivable]="this.archivable"
                               [pageCriteria]="this.pageCriteria"
                               [namedEntityList]="this.namedEntityList"
                               [entityPageSubject]="this.entityPageSubject"
                               [entityService]="this.getEntityService()"
                               [allowTextCriteria]="this.allowTextCriteria"
                               [allowDescriptiveTextSort]="this.allowDescriptiveTextSort"
                               [sortDirection]="this.sortDirection"
                               [sortField]="this.sortField"></app-list-filter>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 offset-lg-0 offset-xl-0 text-right">
              <ng-container
                *ngTemplateOutlet="templateCreate; context: {pageContext: this.pageCriteria}"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <ng-container
        *ngTemplateOutlet="templateValidationMessage; context: {pageCriteria: this.pageCriteria}"></ng-container>
    </div>

    <div class="row">
      <div class="mb-1 col-sm-12 col-md-6 col-lg-3 col-xl-3"
           [class]="{'offset-lg-9': this.dataLength() <= 3 }"
           *ngFor="let element of this.entityPageSubject?.getValue()?.data">

        <app-filter-page-list-card-container [element]="element" [ngtButton]="templateButton"
                                             [ngtTitle]="templateTitle"></app-filter-page-list-card-container>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 offset-lg-0 offset-xl-0 text-right">
        <app-list-pager [entityService]="this.getEntityService()"
                        [namedEntityList]="this.namedEntityList"
                        [entityPageSubject]="this.entityPageSubject"></app-list-pager>
      </div>
    </div>

  </div>
</div>
