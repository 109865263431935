import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseAddEditComponent} from "../base/base-add-edit.component";
import {ClientContact} from "../domain/client-contact";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {ClientContactService} from "./client-contact-service";

@Component({
  selector: 'app-client-contact',
  templateUrl: './app-client-contact.component.html',
  styleUrls: ['./app-client-contact.component.css']
})
export class AppClientContactComponent extends BaseAddEditComponent<ClientContact> {
  formElements = {
    "clientId": this.az09_(1, 250),
    "firstName": this.az09_(2, 250),
    "lastName": this.az09_(2, 250),
    "email": this.email(),
    "cellPhone": this.phone(),
    "memo": this.simple(1, 256),
  };

  constructor(location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              entityService: ClientContactService,
              fb: FormBuilder,
              protected changeDetectorRef: ChangeDetectorRef) {
    super(location, router, activatedRoute, entityService, fb);
  }

  /* TODO - changeDetectorRef needs to move to super, but that will cause all children to break */
  ngOnInit() {
    super.ngOnInit();
    this.changeDetectorRef.detectChanges();
  }

  public entityName(): string {
    return this.entityFormGroup.get('clientId').value ? "contact" : "organizational-contact";
  }

  getFormElements(): Record<string, any> {
    return this.formElements;
  }

  protected handleParametersExtend(id: string, queryParams: Record<string, string>) {
    this.entityFormGroup.patchValue({"clientId": queryParams["clientId"]});
  }

  updateFormGroup(v: ClientContact): any {
    const newEntityFormGroupValue = Object.assign(
      this.fieldsBase(v), {
        clientId: this.fieldDefault(v.client?.id),
        firstName: this.fieldDefault(v.firstName),
        lastName: this.fieldDefault(v.lastName),
        email: this.fieldDefault(v.email),
        cellPhone: this.fieldDefault(v.cellPhone),
        memo: this.fieldDefault(v.memo),
      });

    this.entityFormGroup.setValue(newEntityFormGroupValue);
  }

}
