import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {BaseEntity} from "../base/base-entity";

export interface Hideable {
  hide(): void;
}

@Component({
  selector: 'app-filter-page-list-card-container',
  templateUrl: './app-filter-page-list-card-container.component.html',
  styleUrls: ['./app-filter-page-list-card-container.component.css']
})
export class AppFilterPageListCardContainerComponent implements OnInit, Hideable {

  @Input()
  ngtTitle: TemplateRef<any>;

  @Input()
  ngtButton: TemplateRef<any>;

  @Input()
  element: BaseEntity;

  hidden: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  hide(): void {
    this.hidden = true;
  }



}
