import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ProjectInspection} from "../domain/client-project-inspection";
import {ClientContact} from "../domain/client-contact";

export class InspectionReportContext {
  inspection: ProjectInspection;
  contacts: ClientContact[] = [];

  constructor(params: any = {}) {
    this.inspection = new ProjectInspection(params.inspection);

    for(let contact of params.contacts) {
      this.contacts.push(new ClientContact(contact));
    }
  }
}

@Injectable()
export class InspectionReportService {

  constructor(protected http: HttpClient, protected cookieMgr: CookieMgr) {
  }

  // TODO - Need to refactor base service so that it can accommodate a non-Entity CRUD pattern
  public getReportContext(inspectionId: number) : Observable<InspectionReportContext> {
    // TODO - tech debt - copy paste
    const authorization = this.cookieMgr.getAuthorizationCookie();

    if (!authorization) {
      throw new Error("No Authorization available");
    } else if (!inspectionId) {
      throw new Error("Parameter inspectionId is required");
    }

    const options = {
      headers: new HttpHeaders().set('Authorization', authorization ? authorization : ''),
    };

    const url = `${this.getUrl()}/${inspectionId}`;

    return new Observable<InspectionReportContext>((observer) => {

      this
        .http
        .get(url, options)
        .subscribe((httpResponse: any) => {
          observer.next(this.instanceOfInspectionReportContext(httpResponse));
        }, (error: any) => {
          observer.error({
            "status": error.status,
            "errors": error.error
          });
        });

    });
  }

  sendReport(inspectionId:number, clientContacts: ClientContact[]): Observable<any> {
    // TODO - tech debt - copy paste
    const authorization = this.cookieMgr.getAuthorizationCookie();

    if (!authorization) {
      throw new Error("No Authorization available");
    } else if (!inspectionId) {
      throw new Error("Parameter inspectionId is required");
    }

    const options = {
      headers: new HttpHeaders().set('Authorization', authorization ? authorization : ''),
    };

    const url = `${this.getUrl()}/${inspectionId}`;
    const contacts = clientContacts.map((e) => { return e.id });

    return new Observable((observer) => {
      this
        .http
        .post(url, {
          "contacts": contacts,
          "inspection": {
            "id": inspectionId
          }
        }, options)
        .subscribe((httpResponse:any) => {
          observer.next({});
        }, (error: any) => {
        observer.error({
          "status": error.status,
          "errors": error.error
        });
      });
    });
  }

  protected instanceOfInspectionReportContext(params: any): InspectionReportContext {
    return new InspectionReportContext(params);
  }

  protected getUrl(): string {
    return "/v2/report/inspection";
  }

}
