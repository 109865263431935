import {BaseEntity } from "../base/base-entity";
import {AppUser} from "./app-user";
import {Client} from "./client";
import {Organization} from "./organization";

export class ClientContact extends BaseEntity {
  clientId: string;
  firstName: string;
  lastName: string;
  email: string;
  cellPhone: string;
  memo: string;
  user: AppUser;
  client: Client;
  organization: Organization;

  constructor(params: any = {}) {
    super(params);
    this.clientId = params.clientId;
    this.firstName = params.firstName;
    this.lastName = params.lastName;
    this.email = params.email;
    this.cellPhone = params.cellPhone;
    this.memo = params.memo;
    this.user = new AppUser(params.user);
    this.client = new Client(params.client);
    this.organization = new Organization(params.organization);
  }
}
