import {Component, Input, OnInit} from '@angular/core';

export interface ErrorReportingComponent {
  visibleError: boolean;
}

@Component({
  selector: 'app-error-load',
  templateUrl: './app-error-load.component.html',
  styleUrls: ['./app-error-load.component.css']
})
export class AppErrorLoadComponent implements OnInit {
  @Input()
  errorReportingComponent: ErrorReportingComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
