import {Component, Input} from '@angular/core';
import {BackCancelSave} from "../base/base-add-edit.component";

@Component({
  selector: 'app-bcs-button-group',
  templateUrl: './app-bcs-button-group.component.html',
  styleUrls: ['./app-bcs-button-group.component.css']
})
export class AppBcsButtonGroupComponent {
  @Input()
  backCancelSave: BackCancelSave;

  constructor() { }
}
