import {BaseEntity} from "../base/base-entity";

export class AppUser extends BaseEntity {
  username: string;
  firstName: string;
  lastName: string;
  email: string;

  constructor(params: any = {}) {
    super(params);

    this.username = params.username;
    this.firstName = params.firstName;
    this.lastName = params.lastName;
    this.email = params.email;
  }

}
