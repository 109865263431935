<div class="container-fluid top-pad">

  <div class="row top-pad">
    <div class="col-md-12 col-lg-10 col-xl-4">
      <div class="card rounded-0 h-100">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8 col-lg-8 col-xl-8">
              <div>
                <h5>Select Report Recipients</h5>
                <div class="card-subtitle mb-2 text-muted">
                  <div>
                    <span class="project-meta">Client Name: </span>
                    <span class="project-meta project-meta-value">
                      <a [routerLink]="['/client', this.inspectionReportContext?.inspection.project.client.id]">{{ this.inspectionReportContext?.inspection.project.client.name }}</a>
                    </span>
                  </div>

                  <div>
                    <span class="project-meta">Project Name: </span>
                    <span class="project-meta project-meta-value">
                      <a [routerLink]="['/project', this.inspectionReportContext?.inspection.project.id]">{{ this.inspectionReportContext?.inspection.project.name }}</a>
                    </span>
                  </div>

                  <div>
                    <span class="project-meta">Date of Inspection:  </span>
<!--                    <span class="project-meta project-meta-value">{{ this.inspectionReportContext?.inspection.dateOf }}</span>-->
                    <span class="project-meta project-meta-value">

                      <a [routerLink]="['/inspection', this.inspectionReportContext?.inspection.id]">{{ this.inspectionReportContext?.inspection.dateOf }}</a>
                    </span>
                  </div>

                  <div>
                    <span class="project-meta">Certified by:  </span>
                    <span class="project-meta project-meta-value">{{ this.inspectionReportContext?.inspection.signedBy.firstName }} {{ this.inspectionReportContext?.inspection.signedBy.lastName }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="col-md-12 col-lg-12 col-xl-4">
              <div class="form-group text-right">
                <input type="button"
                       class="btn btn-outline-success"
                       id="send"
                       value="Send" (click)="clickSend()"
                       [disabled]="this.isSending || this.isSelectionEmpty()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
      <div class="col-md-12 col-lg-5 col-xl-4 sub-pad" *ngFor="let contact of this.inspectionReportContext?.contacts">
        <div class="card rounded-0 h-100">
          <div class="card-body">
            <input type="checkbox"
                   name="contacts_client"
                   class="d-none"
            >
            <div>
              <div class="row">
                <div class="col-md-12 col-lg-8">

                  <h5>
                    <span>
                      {{ contact.firstName }}&nbsp;{{ contact.lastName }}
                    </span>
                  </h5>

<!--                  <div class="card-subtitle mb-2" *ngIf="contact.client?.id">-->
<!--                    <span class="project-meta">-->
<!--                      <span class="oi oi-warning"></span>-->
<!--                    </span>-->
<!--                    <span class="project-meta project-meta-value">External Client Contact</span>-->
<!--                  </div>-->

<!--                  <div *ngIf="!contact?.client?.id">-->
<!--                    <span class="project-meta">Organization Contact</span>-->
<!--                  </div>-->

                  <div *ngIf="contact.memo">
                    <span class="project-meta">{{ contact.memo }}</span>
                  </div>

                  <div *ngIf="contact.email">
                    <span class="project-meta">{{ contact.email }}</span>
                  </div>

                </div>
                <div class="col-md-12 col-lg-4">
                  <div class="text-right">

                    <button type="button"
                            class="btn btn-outline-success"
                            (click)="clickSelect(contact)"
                            [disabled]="this.isSending">

                      <span class="oi oi-circle-check" style="padding-right:0.35em;" *ngIf="this.isSelected(contact)"></span>
                      <span>Select</span>

                    </button>

                  </div>
                </div>
              </div>

              <div>
<!--                {#                                    {% if contact_client.client_id %}#}-->
<!--                {#                                        <span class="cub">{{ contact_client.client.name }}</span>#}-->
<!--                {#                                    {% elif contact_client.organization_id %}#}-->
<!--                {#                                        <span class="cub">{{ contact_client.organization.name }}</span>#}-->
<!--                {#                                    {% endif %}#}-->
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
</div>
