<div class="container-fluid top-pad" >

  <div class="row top-pad">

    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <div>
            <h5>Permit Detail</h5>
          </div>

          <div class="card-subtitle mb-2 text-muted">
            <span>Manage a Permit record for a project</span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row sub-pad" *ngIf="this.flashMessage">
    <div class="col-lg-6 col-sm-12">
      <div class="alert alert-danger" role="alert" *ngIf="this.flashMessage.isError">
        <h4 class="alert-heading">{{ this.flashMessage.messageHead }}</h4>
        <p>{{ this.flashMessage.messageSub }}</p>
        <hr>
        <div class="text-right">
          <a class="btn btn-outline-info my-2 my-sm-0" data-dismiss="alert">Dismiss</a>
        </div>
      </div>
    </div>
  </div>


  <div class="row sub-pad">
    <div class="col-lg-6 col-sm-12">
      <div class="card rounded-0">
        <div class="card-body">
          <form [formGroup]="entityFormGroup">
            <input type="hidden" value="" id="id" name="id" />

            <div class="form-group">
              <label for="type">Type</label>
              <input type="text"
                     class="form-control"
                     id="type"
                     name="type"
                     placeholder="Type"
                     formControlName="type"
                     autocomplete="off"
                     required
                     [ngClass]="{'invalid': !this.isValid('type')}">
            </div>

            <validator-msg [component]="this" formElementName="type"></validator-msg>

            <div class="form-group">
              <label for="startValid">Start</label>
              <input type="date"
                     class="form-control"
                     id="startValid"
                     name="startValid"
                     placeholder="Type"
                     formControlName="startValid"
                     autocomplete="off"
                     required
                     [ngClass]="{'invalid': !this.isValid('startValid')}">
            </div>

            <validator-msg [component]="this" formElementName="startValid"></validator-msg>

            <div class="form-group">
              <label for="expire">Expire</label>
              <input type="date"
                     class="form-control"
                     id="expire"
                     name="expire"
                     placeholder="Expire"
                     formControlName="expire"
                     autocomplete="off"
                     required
                     [ngClass]="{'invalid': !this.isValid('expire')}">
            </div>

            <validator-msg [component]="this" formElementName="expire"></validator-msg>

            <div class="form-group">
              <label for="authorizationNo">Authorization Number</label>
              <input type="text"
                     class="form-control"
                     id="authorizationNo"
                     name="authorizationNo"
                     placeholder="Authorization Number"
                     formControlName="authorizationNo"
                     autocomplete="off"
                     required
                     [ngClass]="{'invalid': !this.isValid('authorizationNo')}">
            </div>

            <validator-msg [component]="this" formElementName="authorizationNo"></validator-msg>
            <app-flash-msg [flashMessage]="this.flashMessage"></app-flash-msg>
            <app-bcs-button-group [backCancelSave]="this"></app-bcs-button-group>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
