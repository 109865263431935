<div class="card rounded-0 d-xl-block" *ngIf="this.isVisible()">
  <div class="card-body">
    <button class="btn btn-outline-info mx-1"
            type="button" (click)="this.clickNavigatePrevious()"
            [disabled]="this.isButtonPreviousDisabled()">
      <span class="oi oi-arrow-left"></span>
    </button>

    <button class="btn btn-outline-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
      Page {{ this.entityPageSubject?.getValue().page.current }} of {{ this.entityPageSubject?.getValue().page.count }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <button class="dropdown-item"
              type="button"
              (click)="this.jumpNavigate(p)"
              *ngFor="let p of getPageNavigation()" >
        <span>{{ p }}</span>
      </button>
    </div>

    <button class="btn btn-outline-info mx-1"
            type="button" (click)="this.clickNavigateNext()"
            [disabled]="this.isButtonNextDisabled()">
      <span class="oi oi-arrow-right"></span>
    </button>
  </div>
</div>
