import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {EntityPage, EntityService} from "../base/base-service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {AppListBase} from "../app-list/app-list-base.component";
import {BehaviorSubject} from "rxjs";
import {PageCriteria} from "../base/page-criteria";
import {SortDirection, SortField} from "../app-list-filter/app-list-filter.component";

@Component({
  selector: 'app-filter-page-list',
  templateUrl: './app-filter-page-list.component.html',
  styleUrls: ['./app-filter-page-list.component.css']
})
export class AppFilterPageListComponent extends AppListBase implements OnInit {
  @Input()
  entityPageSubject: BehaviorSubject<EntityPage<any>>;

  @Input()
  entityNameValue: string;

  @Input()
  entityService: EntityService<any>;

  @Input()
  archivable: boolean = false;

  @Input()
  allowTextCriteria: boolean = true;

  @Input()
  allowDescriptiveTextSort: boolean = false;

  @Input()
  sortField: SortField;

  @Input()
  sortDirection: SortDirection;

  @ContentChild('templateTitle')
  templateTitle: TemplateRef<any>;

  @ContentChild('templateButton')
  templateButton: TemplateRef<any>;

  @ContentChild('templateCreate')
  templateCreate: TemplateRef<any>;

  @ContentChild('templateValidationMessage')
  templateValidationMessage: TemplateRef<any>;

  constructor(protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router) {

    super(location);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if(this.sortField != undefined && this.sortDirection != undefined) {
        this.pageCriteria = PageCriteria.fromParams(params, {'sortField': this.sortField, 'sortDirection': this.sortDirection});
      }else {
        this.pageCriteria = PageCriteria.fromParams(params);
      }

      this.fetchPage(this.pageCriteria);
    });

    this.refreshSignal.subscribe((pageCritera: PageCriteria) => {
      if(pageCritera) {
        this.fetchPage(this.pageCriteria);
      }
    });
  }

  public entityName(): string {
    return this.entityNameValue;
  }

  public getEntityService(): EntityService<any> {
    return this.entityService;
  }

  public dataLength(): number {
    return this.entityPageSubject?.getValue()?.data?.length;
  }

}
