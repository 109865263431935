import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Location} from "@angular/common";
import {AppListBase} from "../app-list/app-list-base.component";

export enum SortField {
  descriptiveText = 0,
  created = 1,
  updated = 2,
  creator = 3,
  updater = 4,
}

export class SortFieldLabels {
  public static getLabelSortField(sortField: SortField): string {
    switch(sortField) {
      case SortField.created:
        return "Created";
      case SortField.descriptiveText:
        return "Text";
      case SortField.updated:
        return "Updated";
    }
  }
}

export enum SortDirection {
  ascending = 0,
  descending = 1
}

export class SortDirectionLabels {
  public static getLabelSortDirection(sortDirection: SortDirection): string {
    switch(sortDirection) {
      case SortDirection.ascending:
        return "Ascending";
      case SortDirection.descending:
        return "Descending";
    }
  }
}

@Component({
  selector: 'app-list-filter',
  templateUrl: './app-list-filter.component.html',
  styleUrls: ['./app-list-filter.component.css']
})
export class AppListFilterComponent extends AppListBase {

  @Input()
  archivable: boolean = false;

  @Input()
  allowTextCriteria: boolean = true;

  @Input()
  allowDescriptiveTextSort: boolean = false;

  @Input()
  pageSizeDefault = 12

  archived: boolean;
  desc: string;
  updateHigh: string;
  updateLow: string;
  createHigh: string;
  createLow: string;

  // TODO - Are these 2 fields necessary?
  sortFieldsEnum = SortField;
  sortDirectionEnum = SortDirection;

  @Input()
  sortField: SortField;

  @Input()
  sortDirection: SortDirection;

  sortFieldLabel(sortField: SortField): string {
    return SortFieldLabels.getLabelSortField(sortField);
  }

  sortFieldLabels(): SortField[] {
    const result = [SortField.created, SortField.updated];

    if(this.allowDescriptiveTextSort) {
      result.push(SortField.descriptiveText);
    }

    return result;
  }

  sortDirectionLabel(sortDirection: SortDirection): string {
    return SortDirectionLabels.getLabelSortDirection(sortDirection);
  }

  sortDirectionLabels(): SortDirection[] {
    return [SortDirection.ascending, SortDirection.descending];
  }

  sortFieldChange(sortField: SortField): boolean {
    this.sortField = sortField;
    return this.sortField === sortField;
  }

  sortDirectionChange(sortDirection: SortDirection): boolean {
    this.sortDirection = sortDirection;
    return this.sortDirection === sortDirection;
  }

  constructor(protected location: Location) {
    super(location);
  }

  clickFilterArchivedToggle(): void {
    this.archived = !this.archived;
  }

  clickFilterApply(): void {
    // @ts-ignore
    window.$(".collapse").collapse('hide');

    this.clickNavigate((pageCriteria) => {
      const pageCriteriaNext = this.entityPageSubject?.getValue()?.criteria;
      pageCriteriaNext.page = 1;
      pageCriteriaNext.desc = this.desc;
      pageCriteriaNext.createLow = this.createLow;
      pageCriteriaNext.createHigh = this.updateLow;
      pageCriteriaNext.updateLow = this.updateLow;
      pageCriteriaNext.updateHigh = this.updateHigh;
      pageCriteriaNext.archived = this.archived || false;
      pageCriteriaNext.sortField = this.sortField != undefined? this.sortField : SortField.created;
      pageCriteriaNext.sortDirection = this.sortDirection != undefined? this.sortDirection : SortDirection.descending;

      const projectId = this.entityPageSubject?.getValue()?.page?.context?.project?.id;

      if(projectId !== undefined) {
        pageCriteriaNext.projectId = "" + projectId;
      }

      const clientId = this.entityPageSubject?.getValue()?.page?.context?.client?.id;

      if(clientId !== undefined) {
        pageCriteriaNext.clientId = "" + clientId;
      }

      return pageCriteriaNext;
    });
  }

  clickNavigateSortDirectionClear(): void {
    this.sortField = SortField.created;
    this.sortDirection = SortDirection.descending;
  }

  clickNavigateFilterClear(): void {
    this.desc = "";
    this.createLow = "";
    this.createHigh = "";
    this.updateLow = "";
    this.updateHigh = "";
    this.archived = false;

    this.clickNavigate((pageCriteria) => {
      const pageCriteriaNext = this.entityPageSubject?.getValue()?.criteria;
      pageCriteriaNext.page = 1;
      pageCriteriaNext.desc = this.desc;
      pageCriteriaNext.createLow = this.createLow;
      pageCriteriaNext.createHigh = this.updateLow;
      pageCriteriaNext.updateLow = this.updateLow;
      pageCriteriaNext.updateHigh = this.updateHigh;
      pageCriteriaNext.archived = this.archived || false;

      return pageCriteriaNext;
    });
  }

  handleKeyPress(keyEvent: any): void {
    if(keyEvent && keyEvent.key === 'Enter') {
      this.clickFilterApply();
    }
  }
}
