import {BaseEntity} from "../base/base-entity";
import {FileGroupFile} from "./file-group-file";

export class FileGroup extends BaseEntity {
  files: FileGroupFile[] = null;
  description: string;
  countFile: number;
  countBytes: number;
  relatedEntity: {
    id: number,
    type: number
  };

  constructor(params: any = {}) {
    super(params);

    this.description = params.description;
    this.files = params.files;
    this.countFile = params.summary?.countFiles;
    this.countBytes = params.summary?.countBytes;
    this.relatedEntity = {
      id: params.relatedEntity?.id,
      type: params.relatedEntity?.type,
    };
  }
}
