import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ClientContactService} from "../app-client-contact/client-contact-service";
import {ErrorReportingComponent} from "../app-error-load/app-error-load.component";
import {AppListViewBase} from "../app-list/app-list-view-base";
import {SortDirection, SortField} from "../app-list-filter/app-list-filter.component";

@Component({
  selector: 'app-client-contact-list',
  templateUrl: './app-client-contact-list.component.html',
  styleUrls: ['./app-client-contact-list.component.css']
})
export class AppClientContactListComponent extends AppListViewBase implements ErrorReportingComponent {

  constructor(protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router,
              protected clientContactService: ClientContactService) {

    super(activatedRoute, location, router);
  }

  clickCreateQueryParams(): Record<string, string> {
    return {"clientId": this.pageCriteria.getValue()?.clientId};
  }

  public entityName(): string {
    return this.pageCriteria?.getValue()?.clientId ? "contact" : "organizational-contact";
  }

  public getEntityService(): ClientContactService {
    return this.clientContactService;
  }

  public isDisabled(): boolean {
    return !!!this.pageCriteria?.getValue()?.clientId;
  }

  public getDefaultSortField(): SortField {
    return SortField.descriptiveText;
  }

  public getDefaultSortDirection(): SortDirection {
    return SortDirection.ascending;
  }
}
