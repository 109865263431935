<div class="container-fluid">
  <div class="row">

    <div class="col-sm-12 col-md-10 col-lg-5 col-xl-5 offset-sm-0 offset-md-1 offset-lg-1 offset-xl-1">
      <div class="card rounded-0 h-100" style="margin-top:1.5em">
        <div class="card-body">
          <h4 class="card-title">Tillera</h4>
          <h6 class="card-subtitle mb-2 text-muted">Data acquisition and retention tools for small business</h6>
          <div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-10 col-lg-5 col-xl-5 offset-sm-0 offset-md-1 offset-lg-0">
      <div class="card rounded-0 h-100" style="margin-top:1.5em">
        <div class="card-body">
          <h4 class="card-title">Sign In</h4>
          <h6 class="card-subtitle mb-2 text-muted">Please provide your username and password.</h6>
          <div>
            <form method="POST" action="">

              <div class="form-group">
                <label for="username">Username</label>
                <input class="form-control rounded-0" id="username" name="username" type='text' [(ngModel)]="username" [readonly]="isBusy()"/>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input class="form-control rounded-0" id="password" name="password" type='password' [(ngModel)]="password" [readonly]="isBusy()" />
              </div>
              <div class="form-group text-left">
                <span>{{message}}</span>
              </div>
              <div class="form-group text-right">
                <button type="submit" class="btn btn-primary align-right" (click)="submit()" [disabled]="isBusy()">Sign In</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
