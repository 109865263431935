import {Component, OnInit} from '@angular/core';
import {CookieMgr} from "../util/cookie-mgr";
import {FileGroupSupport} from "../domain/file-group-support";
import {BehaviorSubject} from "rxjs";

class BasicFileGroupSupport implements FileGroupSupport {

  constructor(public id: number,
              public entityType: number,
              public fileGroupId?: number,
              public projectId?: number, ) {}
}

@Component({
  selector: 'app-home',
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.css']
})
export class AppHomeComponent implements OnInit {
  targetSubject: BehaviorSubject<FileGroupSupport> = new BehaviorSubject(null)

  constructor(protected cookieMgr: CookieMgr) { }
  ngOnInit(): void {
    // setTimeout(() => {
      // const v = new BasicFileGroupSupport(null, null, 5);
      // const v = new BasicFileGroupSupport(1348, EntityType.basicSurvey.valueOf(), null);
      // const v = new BasicFileGroupSupport(1348, EntityType.basicSurvey.valueOf(), 5);

      // const v = new BasicFileGroupSupport(5, EntityType.basicSurvey.valueOf(), null);
      // this.targetSubject.next(v);
    // }, 3000);
  }

  organization(): any {
    return this.cookieMgr.getCookieLoginMeta().organization;
  }

  loggedInUsername(): any {
    return this.cookieMgr.getCookieLoginMeta()?.user?.username;
  }
}
