<div class="row" *ngIf="visibleContent">
  <div class="col-sm-9 col-md-9 col-lg-9 col-xl-9" style="">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">
        <h6>
          {{ record?.name }}
        </h6>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">
        <div *ngIf="record?.updated; else elseNotUpdated" class="cub">
          <span class="cub-value">Updated {{ record?.updated?.date | date}}</span>
          <span class=""> by </span>
          <span class="cub-value">{{ record?.updated?.user?.username }}</span>
        </div>

        <ng-template #elseNotUpdated>
          <div class="cub">
            <span class="cub-value">Created {{ record?.created.date | date}}</span>
            <span class=""> by </span>
            <span class="cub-value">{{ record?.created.user.username }}</span>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">
        <div *ngIf="record?.archived" class="cub">
          <span class="cub-value">This record is archived</span>
        </div>
      </div>
    </div>

  </div>
  <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" style="">
    <button type="button" class="btn btn-outline-info my-2 my-sm-0" [disabled]="record?.archived" (click)="clickArchive()"><span
      class="oi oi-trash"></span></button>
  </div>
</div>

<div class="row" *ngIf="visibleConfirmation">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">
        Confirm archive?
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right" style="">
        <span style="padding-right:0.25em;">
          <button type="button" class="btn btn-outline-info my-2 my-sm-0" (click)="clickConfirmationNo()">
            <span>No</span>
          </button>
        </span>
        <span>
          <button type="button" class="btn btn-outline-info my-2 my-sm-0" (click)="clickConfirmationYes()">
            <span>Yes</span>
          </button>
        </span>
      </div>
    </div>

  </div>
</div>

<div class="row" *ngIf="visibleWip">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">
        <span>
          <img src="assets/spinner.gif" style="height:1.25em;"/>
        </span>
        <span>
          Archiving...
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="visibleConclusion">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="">
        <span>
          {{ this.conclusion }}
        </span>
      </div>
    </div>
  </div>
</div>








