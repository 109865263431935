import {Component, OnInit} from '@angular/core';
import {LoginAware} from "./app-login/login.aware";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'v2ui';
  loginAware: LoginAware = new LoginAware();

  ngOnInit(): void {
    const cval = this.loginAware.cookieMgr.getCookie("ts-v2-authorization")

    if(cval) {
      this.loginAware.signalLogin("")
    }
  }
}
