import {Component, OnInit} from '@angular/core';
import {ClientContact} from "../domain/client-contact";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {zip} from "rxjs";
import {InspectionReportContext, InspectionReportService} from "./inspection-report-service";

@Component({
  selector: 'app-inspection-report',
  templateUrl: './app-inspection-report.component.html',
  styleUrls: ['./app-inspection-report.component.css']
})
export class AppInspectionReportComponent implements OnInit {
  inspectionReportContext: InspectionReportContext;
  clientContactsSelected = [];
  isSending = false;

  constructor(protected location: Location,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected inspectionReportService: InspectionReportService) {}

  ngOnInit(): void {
    const observable = zip(this.activatedRoute.queryParams, this.activatedRoute.params);

    observable.subscribe( ([queryParams, id]) => {
      this
        .inspectionReportService
        .getReportContext(id.id)
        .subscribe((inspectionReportContext: InspectionReportContext) => {
          this.inspectionReportContext = inspectionReportContext;
        })
    });
  }

  clickSelect(element: ClientContact) {
    const index = this.clientContactsSelected.indexOf(element);

    if(index === -1) {
      this.clientContactsSelected.push(element);
    }else {
      this.clientContactsSelected.splice(index, 1);
    }
  }

  clickSend() {
    this.isSending = true;
    this
      .inspectionReportService
      .sendReport(this.inspectionReportContext.inspection.id, this.clientContactsSelected)
      .subscribe((succes) => {
        this.router
          .navigateByUrl(`/inspection/list?projectId=${this.inspectionReportContext.inspection.project.id}`)
          .then((z) => {});
      }, (error) => {
        this.isSending = false;
      }, () => {
        this.isSending = false;
      });
  }

  isSelected(element: ClientContact): boolean {
    return this.clientContactsSelected.includes(element);
  }

  isSelectionEmpty(): boolean {
    return this.clientContactsSelected.length == 0;
  }
}

