import {Component, Input, OnInit} from '@angular/core';
import {LoginAware} from "../app-login/login.aware";

@Component({
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.css']
})
export class AppNavComponent implements OnInit {
  @Input() loginAware: LoginAware;

  constructor() { }

  ngOnInit(): void {
    this.loginAware.login.subscribe((e) => {
      console.dir(e);
    });
  }

  clickLogout(): void {
    this.loginAware.signalLogout();
  }
}
