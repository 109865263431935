import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseAddEditComponent, FormEvent} from "../base/base-add-edit.component";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {ClientProjectService} from "./client-project-service";
import {ClientProject} from "../domain/client-project";
import {ClientService} from "../app-client/client.service";
import {BehaviorSubject} from "rxjs";
import {Client} from "../domain/client";

@Component({
  selector: 'app-project',
  templateUrl: './app-project.component.html',
  styleUrls: ['./app-project.component.css']
})
export class AppProjectComponent extends BaseAddEditComponent<ClientProject> {
  selectedClientId = null;
  visibleClientSelect = false;

  formElements = {
    "clientId": this.selectOne(),
    "name": this.az09_(2, 250),
    "coordLat": this.lonlat(),
    "coordLon": this.lonlat(),
    "address1": this.az09_(2, 250),
    "address2": this.az09_(2, 250),
    "city": this.az09_(2, 250),
    "state": this.az(2, 2),
    "zip": this.zipcode(),
    "receivingWaterOutfall": this.az09_(2, 250),
    "acresDisturbed": this.decimal09(),
  };

  constructor(location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              entityService: ClientProjectService,
              fb: FormBuilder,
              protected changeDetectorRef: ChangeDetectorRef,
              protected clientService: ClientService) {
    super(location, router, activatedRoute, entityService, fb);
  }

  /* TODO - changeDetectorRef needs to move to super, but that will cause all children to break */
  ngOnInit() {
    super.ngOnInit();

    this.changeDetectorRef.detectChanges();

    this.formEventSubject.subscribe((o) => {
      switch(o) {
        case FormEvent.saved: this.visibleClientSelect = false; break;
      }
    });
  }

  entityName(): string {
    return "project";
  }

  getFormElements(): Record<string, any> {
    return this.formElements;
  }

  subjectClientList = new BehaviorSubject<Client[]>([]);

  protected handleParametersExtend(id: string, queryParams: Record<string, string>) {
    const clientId = queryParams["clientId"];
    this.entityFormGroup.patchValue({"clientId": clientId});

    if(!!!clientId && !!!id) {
      this.visibleClientSelect = true;
      const result = this.clientService.getPage(100, 1);
      result.subscribe((p) => {
        this.subjectClientList.next(p.data);
      })
    }
  }

  updateFormGroup(v: ClientProject): any {
    const newEntityFormGroupValue = Object.assign(
      this.fieldsBase(v), {
        clientId: this.fieldDefault(v.clientId),
        name: this.fieldDefault(v.name),
        coordLat: this.fieldDefault(v.coordLat),
        coordLon: this.fieldDefault(v.coordLon),
        address1: this.fieldDefault(v.address1),
        address2: this.fieldDefault(v.address2),
        city: this.fieldDefault(v.city),
        state: this.fieldDefault(v.state),
        zip: this.fieldDefault(v.zip),
        receivingWaterOutfall: this.fieldDefault(v.receivingWaterOutfall),
        acresDisturbed: this.fieldDefault(v.acresDisturbed),
      });

    this.entityFormGroup.setValue(newEntityFormGroupValue);
  }

}
