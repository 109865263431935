<div class="container-fluid top-pad">

  <app-error-load [errorReportingComponent]="this"></app-error-load>

  <app-filter-page-list [entityService]="getEntityService()"
                        [entityNameValue]="entityName()"
                        [namedEntityList]="this"
                        [refreshSignal]="this.refreshSignal"
                        [allowTextCriteria]="false"
                        [allowDescriptiveTextSort]="false"
                        [sortDirection]="this.getDefaultSortDirection()"
                        [sortField]="this.getDefaultSortField()">

    <ng-template #templateCreate>
      <button type="button" class="btn btn-outline-success mr-1" (click)="clickCreate()">Create {{ this.entityNameCapitalized() }}</button>
      <button type="button" class="btn btn-outline-info" (click)="clickViewProject()">View Project</button>
    </ng-template>

    <ng-template #templateValidationMessage let-pageCriteria="pageCriteria">
      <div class="row top-pad" *ngIf="this.dialogPermitRequiredVisible()">
        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 offset-lg-4 offset-xl-4" style="padding-bottom:0.55em;">
          <div class="card rounded-0 card-body top">
            <div>
              This project requires a permit for each inspection.
            </div>
            <div class="text-right top-pad">
              <a [routerLink]="['/permit']"
                 [queryParams]="{'projectId': pageCriteria.projectId}"
                 class="btn btn-outline-success">Create Permit</a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>


    <ng-template #templateTitle let-element="element">
      <span class="flagged" *ngIf="element.flagged">
        <span class="oi oi-flag"></span>
      </span>
      <span>Completed {{ element.dateOf }}</span>
    </ng-template>

    <ng-template #templateButton let-element="element">
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickEdit(element)" [disabled]="element.project.archived">Edit</button>
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickReport(element)">Report</button>
      <button type="button" class="btn btn-outline-info my-2 mr-1" (click)="clickClone(element)" [disabled]="element.project.archived">Clone</button>
    </ng-template>

  </app-filter-page-list>

</div>
