import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from "@angular/common/http";
import {CookieMgr} from "../util/cookie-mgr";
import {FormBuilder, Validators} from "@angular/forms";
import {FileGroupSupport} from "../domain/file-group-support";
import {BehaviorSubject, Observable} from "rxjs";
import {FileGroup} from "../domain/file-group";
import {FileGroupService} from "./file-group-service";
import {FileGroupFile} from "../domain/file-group-file";

// # TODO - @Input limit=number - usecase profile avatar, client avatar, etc

@Component({
  selector: 'app-file-group',
  templateUrl: './app-file-group.component.html',
  styleUrls: ['./app-file-group.component.css']
})
export class AppFileGroupComponent {

  @Input()
  fileGroupSupportSubject: BehaviorSubject<FileGroupSupport>;

  fileGroupSubject: BehaviorSubject<FileGroup> = new BehaviorSubject<FileGroup>(null);

  obs: Observable<number>;


  accept = ".gif,.mp4,.jpeg,.jpg,.pdf,.png,.tif,.tiff";
  files: File[] = null;

  labelTextUpload = "Upload";
  labelTextUploading = "Uploading...";

  labelChooseFilesDefault = "Choose file(s)";
  labelChooseFiles = `${this.labelChooseFilesDefault}`;
  labelUpload = `${this.labelTextUpload}`;


  disabledLabelChooseFiles = false;
  disabledButtonUpload = true;

  entityNew = false;
  loading = true;
  selectingUploading = false;
  viewExistingFiles = false;

  // TODO - Debt - refactor the validators to be used outside of baseEntity if this cannot be one
  entityFormGroup = this.fb.group({
    description: ['', [Validators.minLength(0), Validators.maxLength(255), Validators.pattern("^[ a-zA-Z0-9\-_\'\.]+$")]]
  });

  constructor(protected httpClient: HttpClient,
              protected cookieMgr: CookieMgr,
              protected fb: FormBuilder,
              protected fileGroupService: FileGroupService) { }

  @ViewChild('fileUpload')
  myInputVariable: ElementRef;

  ngOnInit(): void {

    this.fileGroupSupportSubject.subscribe((n) => {
      if(!n) {
        // Seems to be the case when the entity is new
        // TODO - this leaves the spinner going
        return;
      }
      // If getFileGroupId exists, the fileGroup should exist, so fetch
      // If getFileGroupId does not exist, get by EntityId and EntityType
      //    This prevents all models from having to fetch their fileGroup
      // If none of the above, show new FileGroup dialogue

      if(n.fileGroupId != null) {
        this.fileGroupService
            .getById(n.fileGroupId)
            // .pipe(timeout(30)) // TODO - use this to limit the load time without response
            .subscribe((fileGroup)=> {
              this.fileGroupSubject.next(fileGroup);
              this.entityFormGroup.patchValue({"description": fileGroup.description});
              this.loading = false;
              // this.obs = new Observable<number>((e) => {
              //   e.next(fileGroup.countFile);
              // })
            }, (error) => {
              console.log(error);
            });
      }else if(n.id != null && n.entityType != null) {
        this.entityNew = false;
        this.fileGroupService
          .getByEntityIdAndType(n.id, n.entityType)
          .subscribe((fileGroup)=> {
            this.fileGroupSubject.next(fileGroup);
            this.entityFormGroup.patchValue({"description": fileGroup.description});
            this.loading = false;
          }, (error) => {
            if(error.status === 404) {
              this.fileGroupSubject.next(new FileGroup({
                relatedEntity: {
                  id: n.id,
                  type: n.entityType
                }
              }));
              this.loading = false;
            }else {
              // TODO - Show another error message
            }
          });
      }else {
        console.log("Load condition 2");
        // TODO - Brand new Inspections do not have ID's yet. so this block would be hit.
        //  Might need to save the inspection before adding photos (always hit this)
        // TODO - Show a prompt that says "Save the Inspection to add Files"
        // TODO - Prevent button usage before file group name is added
        console.log("new inspection?")
        this.loading = false;
        this.entityNew = true;
      }

    });
  }

  getDescriptionDefault(): string {
    return this.fileGroupSupportSubject?.getValue().descriptionDefault;
  }

  clickDownload(fileGroupId: number, fileId: number) {
    this.fileGroupService.handleDownload(fileGroupId, fileId);
  }

  clickSelectFiles(event) {
    this.myInputVariable.nativeElement.click();

    if(this.disabledLabelChooseFiles) {
      event.preventDefault();
    }
  }

  clickViewFiles() {
    // console.dir(this.fileGroupSubject?.value);
    this.labelUpdateCountBytesExistingFiles(this.fileGroupSubject?.value?.countFile, this.fileGroupSubject?.value?.countBytes);
    this.viewExistingFiles = true;
  }

  clickViewFilesRefresh() {
    // TODO - Disable refresh button for duration of operation
    const fileGroup = this.fileGroupSubject.getValue();
    if(!fileGroup) {
      return;
    }

    this.fileGroupService
      .getById(fileGroup.id)
      .subscribe((fileGroup)=> {
        this.fileGroupSubject.next(fileGroup);
        this.entityFormGroup.patchValue({"description": fileGroup.description});
      }, (error) => {
        console.log(error);
      });
  }

  clickViewFilesClose() {
    this.viewExistingFiles = false;
  }


  labelUploadError: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  labelUpdateUploadError(value: string = null) {
    this.labelUploadError.next(value);
  }

  labelCountBytesSelectedFiles: BehaviorSubject<string> = new BehaviorSubject<string>("0 bytes");

  labelUpdateCountBytesSelectedFiles(value: number = 0) {
    this.labelCountBytesSelectedFiles.next(`${value.toLocaleString()} bytes`);
  }

  labelCountBytesExistingFiles: BehaviorSubject<string> = new BehaviorSubject<string>("0 bytes");

  labelUpdateCountBytesExistingFiles(countFile: number, countByte: number) {
    const countFileDisplay = countFile === undefined ? 0 : countFile.toLocaleString();
    const countByteDisplay = countByte === undefined ? 0 : countByte.toLocaleString();

    this.labelCountBytesExistingFiles.next(`${countFileDisplay} files, ${countByteDisplay} bytes`);
  }

  labelCancelCloseValue: BehaviorSubject<string> = new BehaviorSubject<string>("Close");

  labelUpdateCancelClose() {
    if(this.files.length > 0) {
      this.labelCancelCloseValue.next("Cancel");
    }else {
      this.labelCancelCloseValue.next("Close");
    }
  }

  clickCancel() {
    this.files = [];
    this.selectingUploading = false;
    this.labelUpdateCancelClose();
    this.labelUpdateUploadError();
  }

  clickCancelDescription() {
    this.entityFormGroup.patchValue({"description": this.fileGroupSubject?.value?.description});
    this.entityFormGroup.markAsPristine()
  }

  clickSaveDescription() {
    this.fileGroupSubject.value.description = this.entityFormGroup.get("description").value
    this
      .fileGroupService
      .persist(this.fileGroupSubject.value)
      .subscribe((r) => {
        // TODO - Debt
        this.fileGroupSubject.value.id = r.id;
        this.fileGroupSubject.value.created = r.created;
        this.fileGroupSubject.value.updated = r.updated;
        this.entityFormGroup.markAsPristine();
      })
  }

  clickAddFiles() {
    // TODO - This is only possible if the filegroup is saved?
    this.files = [];
    this.selectingUploading = true;
    this.labelUpdateCancelClose();
    this.labelUpdateCountBytesSelectedFiles();
  }

  clickUpload() {
    this.disabledButtonUpload = true;
    const fileGroupDescription = this.entityFormGroup.get("description").value
    // console.log(this.entityFormGroup.get("description").valid);
    // return;

    this.labelUpload = `${this.labelTextUploading}`;
    // TODO - disable file select label
    // this.disabledLabelChooseFiles = true;
    // this.disabledUploadButton = true;
    // this.pickerButtonDisabled = true;
    //
    const formData: FormData = new FormData();

    // formData.append("id", `${this.id ? this.id : ""}`);
    // formData.append("created", this.created);
    // formData.append("updated", this.updated);
    const fileGroup = this.fileGroupSubject.getValue();

    formData.append("id", `${fileGroup.id ? fileGroup.id : ""}`);
    formData.append("created", `${fileGroup.created}`);
    formData.append("updated", `${fileGroup.updated}`);

    if(this.entityFormGroup.get("description").value) {
      formData.append("description", this.entityFormGroup.get("description").value);
    }else {
      formData.append("description", this.fileGroupSupportSubject?.getValue().descriptionDefault);
    }

    const fileGroupSupport = this.fileGroupSupportSubject.getValue();
    formData.append("entity_id", fileGroupSupport.id.toString());
    formData.append("entity_type", fileGroupSupport.entityType.toString());

    this.files.forEach((f: File) => {
      formData.append('file', f, f.name);
    });

    // TODO - Refactor - Authorization handling should be a small method ; its copy pasted a bunch now
    const authorization = this.cookieMgr.getAuthorizationCookie();

    if (!authorization) {
      throw new Error("No Authorization available");
    }

    const headers =  new HttpHeaders().set('Authorization', authorization ? authorization : '');

    this.httpClient
      // .request(new HttpRequest('POST', "/v2/upload", formData, { headers: result, reportProgress: true }))
      .request(new HttpRequest('POST', "/v2/upload", formData, { headers: headers, reportProgress: true }))
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          let progress = Math.round((event.loaded / event.total) * 100);
          // if(100 === progress) {
          //   this.labelUpload = `${this.labelWaiting}`;
          // }else {
          //   this.labelUpload = `${this.labelUploadingFragment} (${progress}%)...`;
          // }
        }

        if (event.type === HttpEventType.Response) {
          // this.fileGroupFileCount = event.body['fileGroupFileCount'];
          // console.dir(event.body['persistedFileGroup']);
          // this.persistedFileGroup = event.body['persistedFileGroup'];

          const nextFileGroup = new FileGroup(event.body["fileGroup"].data[0]);
          this.entityFormGroup.patchValue({"description": nextFileGroup.description});
          this.fileGroupSubject.next(nextFileGroup);

          // Update the list


          // this.fileUploadFormReset();
          this.myInputVariable.nativeElement.value = "";

          this.files = [];
          this.disabledButtonUpload = false;
          this.selectingUploading = false
        }
      }, (error) => {
        this.labelUpdateUploadError("Encounter error during upload");
        this.disabledButtonUpload = false;
      });
  }

  isEntityNew(): boolean {
    return this.entityNew;
  }

  isLoading(): boolean {
    return this.loading;
  }

  isViewExistingFilesVisible(): boolean {
    return this.viewExistingFiles;
  }

  isMainCommandRowVisible(): boolean {
    return !this.selectingUploading && !this.viewExistingFiles;
  }

  isFileUploadVisible(): boolean {
    return this.selectingUploading;
  }

  isUploadButtonDisabled(): boolean {
    // const isDescriptionInvalid = this.entityFormGroup.get("description").value.length === 0;
    // return this.disabledButtonUpload || isDescriptionInvalid;
    return this.disabledButtonUpload;
  }

  change(files: FileList) {
    // this.disabledUploadButton = !!(files.length === 0);
    this.files = [];
    let countBytes = 0;

    for(let i=0;i<files.length;i++) {
      this.files.push(files.item(i));
      countBytes += files.item(i).size;
      // console.log(`\t: ${files[i].name} ${files[i].size}`);
    }

    // console.log(`change: ${this.files}`);
    this.disabledButtonUpload = this.files.length == 0;
    this.labelUpdateCancelClose();
    this.labelUpdateCountBytesSelectedFiles(countBytes);
  }

  progress(event: any) {
    console.log(`progress ${event}`);
  }

  waiting(event: any) {
    console.log(`waiting ${event}`);
  }

  isDownloadDisabled(file: FileGroupFile): boolean {
    return file.processing_state <=5;
  }
}
