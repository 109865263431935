import {BaseEntity} from "../base/base-entity";
import {ArchivableEntity} from "./archivable-entity";

export class Client extends BaseEntity implements ArchivableEntity {
  name: string;
  archived: boolean;

  constructor(params: any = {}) {
    super(params);
    this.name = params.name;
  }
}
