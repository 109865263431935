import {Component} from '@angular/core';
import {Location} from "@angular/common";
import {AppListBase} from "../app-list/app-list-base.component";

@Component({
  selector: 'app-list-pager',
  templateUrl: './app-list-pager.component.html',
  styleUrls: ['./app-list-pager.component.css']
})
export class AppListPagerComponent extends AppListBase {

  constructor(protected location: Location) {
    super(location);
  }

  clickNavigateNext(): void {
    if (this.entityPageSubject.getValue()?.page.last) {
      return;
    }

    this.clickNavigate((pageCriteria) => {
      pageCriteria.page = pageCriteria.page + 1;
      return pageCriteria;
    });
  }

  clickNavigatePrevious(): void {
    if (this.entityPageSubject.getValue()?.page.first) {
      return;
    }

    this.clickNavigate((pageCriteria) => {
      pageCriteria.page = pageCriteria.page - 1;
      return pageCriteria;
    });
  }

  isVisible(): boolean {
    const entityPageSubject = this.entityPageSubject.getValue();

    if(!entityPageSubject) {
      return false;
    }else if(!entityPageSubject.page) {
      return false;
    }

    return !(entityPageSubject.page.first && entityPageSubject.page.last);
  }

  isButtonPreviousDisabled(): boolean {
    return this.entityPageSubject.getValue()?.page?.first;
  }

  isButtonNextDisabled(): boolean {
    return this.entityPageSubject.getValue()?.page?.last;
  }

  jumpNavigate(p): void {
    if(isNaN(p)) {
      return;
    }

    this.clickNavigate((pageCriteria) => {
      pageCriteria.page = p;
      return pageCriteria;
    });
  }

  getPageNavigation(): Array<number> {
    const pageNavigation = [];
    const max = 10;
    const current = this.entityPageSubject?.getValue()?.page.current;
    const count = this.entityPageSubject?.getValue()?.page.count;

    if(!this.entityPageSubject?.getValue().page.last) {
      for(let i=current+1; i<=count && i<=max; i++) {
        pageNavigation.push(i);
      }
    }else {
      for(let i=current - 1; i>=1 && i<=max; i--) {
        pageNavigation.push(i);
      }
    }

    return pageNavigation;
  }
}
